<template>
  <v-dialog :value="showSiteNameDialog" width="500" persistent>
    <validation-observer ref="siteNameForm" slim>
      <form @submit.prevent="save">
        <v-card class="px-4 py-2">
          <v-card-title> Name Site </v-card-title>

          <v-card-text>
            <p>Give your site a name before you create a site contacts:</p>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Value"
              :rules="{ required: true }"
            >
              <v-text-field
                v-model="site.name"
                clearable
                :error-messages="errors"
                :success="valid"
                label="Site Name"
                hide-details
              >
              </v-text-field>
            </validation-provider>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              elevation="0"
              raised
              text
              color="#3F51B5"
              @click="$emit('close')"
            >
              Cancel
            </v-btn>

            <v-btn color="#3F51B5" type="submit" dark> Save </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SiteNameDialog",
  props: {
    showSiteNameDialog: Boolean,
    existingSiteName: String,
    selectedMapServiceId: String,
    objectId: Number,
  },
  data() {
    return {
      site: {},
    };
  },
  methods: {
    async save() {
      const success = await this.$refs.siteNameForm.validate();
      if (!success) {
        return;
      }
      const { site } = this;
      const { name } = site;
      await axiosWithRegularAuth.put(`${APIURL}/shared_sites/${site.site_id}`, {
        name,
      });
      this.$emit("site-name-saved");
    },
    async getSharedSite() {
      const { selectedMapServiceId, objectId } = this;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/shared_sites/${selectedMapServiceId}/${objectId}`
      );
      this.site = { ...results };
    },
  },
  beforeMount() {
    this.getSharedSite();
  },
};
</script>
