var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.showConfirmRemoveSiteContactDialog,
        callback: function($$v) {
          _vm.showConfirmRemoveSiteContactDialog = $$v
        },
        expression: "showConfirmRemoveSiteContactDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "m-2" },
        [
          _c("v-card-text", [
            _c("div", { staticClass: "pt-5" }, [
              _c("h2", { staticClass: "mb-3" }, [
                _vm._v("Remove Site Contact?")
              ]),
              _c("p", [
                _vm._v(
                  "Are you sure you want to remove this person as a site contact?"
                )
              ])
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { elevation: "0", text: "", color: "#673AB7" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "#673AB7" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("remove-site-contact")
                    }
                  }
                },
                [_vm._v(" Remove Site Contact ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }