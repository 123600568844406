var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showContactAlreadyLinkedDialog,
        width: "500",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "m-2" },
        [
          _c("v-card-text", [
            _c("div", { staticClass: "pt-5" }, [
              _vm._v("That contact is already linked to this site.")
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "0",
                    raised: "",
                    text: "",
                    color: "#673AB7"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }