import { mapMutations } from "vuex";

export default {
  data() {
    return { showNotificationPane: false };
  },
  methods: {
    ...mapMutations(["setShowingNotificationPane"]),
    onShowNotifications() {
      this.showNotificationPane = true;
      this.setShowingNotificationPane(true);
    },
  },
  watch: {
    showNotificationPane(val) {
      if (val) {
        this.showTicketLogTab = false;
        this.showTasksTab = false;
        this.showDocsTab = false;
        this.showGisInfoPanel = false;
        this.showFeatureLayerGisInfoPanel = false;
      }
    },
  },
};
