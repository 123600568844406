<template>
  <v-dialog v-model="showConfirmRemoveSiteContactDialog" width="500">
    <v-card class="m-2">
      <v-card-text>
        <div class="pt-5">
          <h2 class="mb-3">Remove Site Contact?</h2>
          <p>Are you sure you want to remove this person as a site contact?</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" text color="#673AB7" @click="$emit('cancel')">
          Cancel
        </v-btn>

        <v-btn dark color="#673AB7" @click="$emit('remove-site-contact')">
          Remove Site Contact
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmRemoveSiteContactDialog",
  props: {
    showConfirmRemoveSiteContactDialog: Boolean,
  },
};
</script>
