<template>
  <v-card flat color="#3F51B5" width="100%" class="rounded-0">
    <v-list color="#3F51B5" class="my-0 py-1" width="100%">
      <v-list-item>
        <v-list-item-content class="py-0 my-0" two-line>
          <section
            class="d-flex justify-space-between align-center overflow-hidden"
          >
            <div
              class="overflow-hidden flex-grow-1"
              v-if="selectedMapService && selectedMapService.site_enabled"
            >
              <SharedSiteEditorForm
                :title="title"
                :selectedMapServiceId="selectedMapServiceId"
                :objectId="objectId"
                :sharedSite="sharedSite"
                @edited-site="$emit('edited-site')"
              />
            </div>
            <div class="overflow-hidden flex-grow-1" v-else>
              <div
                class="
                  d-flex
                  justify-space-between
                  my-2
                  overflow-hidden
                  text-truncate
                  small
                "
              >
                <div class="white--text overflow-hidden text-truncate">
                  {{ title }}
                </div>
              </div>

              <div class="white--text overflow-hidden text-truncate">
                {{ subtitle }}
              </div>
            </div>

            <div class="d-flex flex-grow-0">
              <v-menu offset-y v-if="showFeatureMenu">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    :min-width="0"
                    :width="0"
                    id="featureMoreOptions"
                  >
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list class="px-0 mx-0">
                  <v-list-item
                    class="px-4 mx-0"
                    @click="$emit('move-feature', objectId)"
                    id="moveFeatureBtn"
                  >
                    <v-icon class="mr-2">{{ mdiArrowAll }}</v-icon>
                    Move
                  </v-list-item>

                  <v-list-item
                    class="px-4 mx-0"
                    @click="$emit('delete-feature', objectId)"
                    id="deleteFeatureBtn"
                  >
                    <v-icon class="mr-2"> {{ mdiDelete }} </v-icon>
                    Delete
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                icon
                text
                @click="$emit('open-expand-panel-dialog')"
                style="font-size: 12px"
                v-if="showExpandButton"
                id="expandPanelBtn"
              >
                <v-icon color="white">
                  {{ mdiArrowExpand }}
                </v-icon>
              </v-btn>

              <v-btn
                icon
                text
                @click="$emit('gis-info-panel-close')"
                style="font-size: 12px"
              >
                <v-icon color="white">
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </div>
          </section>

          <div
            class="d-flex align-center caption mx-n3"
            v-if="gisInfos.length > 1"
          >
            <v-btn text x-small @click="$emit('prev')" class="px-0 mx-0">
              <v-icon color="white">{{ mdiChevronLeft }}</v-icon>
            </v-btn>
            <div class="white--text">
              {{ selectedGisInfoIndex + 1 }} of {{ gisInfos.length }}
              <span v-if="$vuetify.breakpoint.smAndUp">Selected</span>
            </div>
            <v-btn text x-small @click="$emit('next')">
              <v-icon color="white">{{ mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiChevronLeft,
  mdiChevronRight,
  mdiArrowExpand,
  mdiDotsVertical,
  mdiDelete,
  mdiArrowAll,
  mdiPencil,
} from "@mdi/js";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import { db } from "@/mixins/utilisync-db";
import SharedSiteEditorForm from "@/components/shared/SharedSiteEditorForm";

export default {
  name: "GisInfoTopCard",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    attributes: Object,
    showFeatureMenu: Boolean,
    sharedSite: Object,
    selectedGisInfoIndex: Number,
    gisInfos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { SharedSiteEditorForm },
  mixins: [gisInfoMixin],
  computed: {
    notUtiliSyncLayer() {
      return this.selectedMapService?.service_type !== "U";
    },
    title() {
      return this.selectedMapService?.service_name;
    },
    subtitle() {
      const { gisInfos, selectedGisInfoIndex } = this;
      if (this.notUtiliSyncLayer) {
        const selectedGisInfo = gisInfos?.[selectedGisInfoIndex];
        return (
          this.attributes?.[this.selectedMapService?.ref_field] ??
          selectedGisInfo?.sourceLayer?.layer?.id ??
          "[Not Provided]"
        );
      }
      const { ref_field: refField } = this.selectedMapService ?? {};
      return (
        this.gisDataFieldsAndValues?.find((f) => f.name === refField)?.value ??
        "[Not Provided]"
      );
    },
    showExpandButton() {
      return ["F", "U"].includes(this.selectedMapService?.service_type);
    },
  },
  data() {
    return {
      mdiClose,
      mdiChevronLeft,
      mdiChevronRight,
      mdiArrowExpand,
      mdiDotsVertical,
      mdiDelete,
      mdiArrowAll,
      mdiPencil,
      selectedMapService: {},
      gisDataFieldsAndValues: [],
    };
  },
  methods: {
    async getMapService() {
      const [mapService] = await db.mapServices
        .filter((m) => {
          return m.map_service_id === this.selectedMapServiceId;
        })
        .toArray();
      this.selectedMapService = mapService;
    },
    async getGisDataFieldsAndValues() {
      const { selectedMapServiceId, objectId } = this;
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const gisDataValueIds = gisDataValues.map((g) => g.gis_data_field_id);
      const gisDataFields = await db.gisDataFields
        .filter((g) => {
          return (
            g.map_service_id === selectedMapServiceId &&
            !gisDataValueIds.includes(objectId)
          );
        })
        .toArray();
      this.gisDataFieldsAndValues = gisDataFields?.map((gdf) => {
        const { name } = gdf;
        const value = gisDataValues?.find((gdv) => {
          return (
            gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
            gdv?.feature_id === objectId
          );
        })?.value;

        return {
          name,
          value,
        };
      });
    },
  },
  async beforeMount() {
    await this.getGisDataFieldsAndValues();
    await this.getMapService();
  },
  watch: {
    async globalId() {
      await this.getGisDataFieldsAndValues();
      await this.getMapService();
    },
    async objectId() {
      await this.getGisDataFieldsAndValues();
      await this.getMapService();
    },
    async selectedMapServiceId() {
      await this.getGisDataFieldsAndValues();
      await this.getMapService();
    },
  },
};
</script>
