import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      taskCount: undefined,
      objectId: undefined,
    };
  },
  methods: {
    async getActiveTasks() {
      const { objectId, selectedMapServiceId } = this;
      if (!objectId || !selectedMapServiceId || !navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/action_item_and_tasks`, {
        params: {
          map_service_id: selectedMapServiceId,
          object_id: objectId,
        },
      });
      const tasks = results;
      const filteredTasks = tasks.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled"
      );
      this.taskCount = filteredTasks.length;
    },
  },
  watch: {
    async objectId() {
      await this.getActiveTasks();
    },
    async selectedMapServiceId() {
      await this.getActiveTasks();
    },
  },
  mounted() {
    this.getActiveTasks();
  },
};
