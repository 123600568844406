<template>
  <section>
    <div
      class="
        d-flex
        justify-space-between
        my-2
        overflow-hidden
        text-truncate
        small
      "
    >
      <div class="white--text overflow-hidden text-truncate">
        {{ title }}
      </div>
    </div>

    <validation-observer ref="sharedSiteEditorForm">
      <form @submit.prevent="onSubmit">
        <validation-provider
          v-if="editing.name"
          v-slot="{ errors, valid }"
          name="Site Name"
          :rules="{
            required: true,
          }"
        >
          <v-text-field
            v-model="currentSharedSite.name"
            label="Site Name"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            name="name"
            color="white"
            height="20px"
            class="field"
            dark
          />
          <div class="d-flex justify-end py-1">
            <v-btn
              text
              outlined
              dark
              color="white"
              small
              @click="
                $set(editing, 'name', false);
                currentSharedSite = { ...originalSharedSite };
              "
            >
              Cancel
            </v-btn>
            <v-btn outlined dark color="white" small @click="onSubmit">
              Save
            </v-btn>
          </div>
        </validation-provider>
        <div class="d-flex align-center gap white--text" v-else>
          <v-btn
            icon
            color="white"
            x-small
            @click="
              $set(editing, 'name', true);
              currentSharedSite = { ...originalSharedSite };
            "
          >
            <v-icon>
              {{ mdiPencil }}
            </v-icon>
          </v-btn>
          <div>{{ currentSharedSite.name || "Site Name" }}</div>
        </div>

        <validation-provider
          v-if="editing.address"
          v-slot="{ errors, valid }"
          name="Address"
          :rules="{
            required: true,
          }"
        >
          <v-text-field
            v-model="currentSharedSite.address"
            label="Address"
            hide-details="auto"
            :error-messages="errors"
            :success="valid"
            name="address"
            color="white"
            height="20px"
            class="field"
            dark
          />
          <div class="d-flex justify-end py-1">
            <v-btn
              text
              dark
              color="white"
              small
              @click="
                $set(editing, 'address', false);
                currentSharedSite = { ...originalSharedSite };
              "
            >
              Cancel
            </v-btn>
            <v-btn outlined dark color="white" small @click="onSubmit">
              Save
            </v-btn>
          </div>
        </validation-provider>
        <div class="d-flex align-center gap white--text small" v-else>
          <v-btn
            icon
            color="white"
            x-small
            @click="$set(editing, 'address', true)"
          >
            <v-icon>
              {{ mdiPencil }}
            </v-icon>
          </v-btn>
          <div>{{ currentSharedSite.address || "Address" }}</div>
        </div>
      </form>
    </validation-observer>
  </section>
</template>

<script>
import { mdiPencil } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteEditorForm",
  props: {
    title: String,
    selectedMapServiceId: String,
    objectId: Number,
    sharedSite: Object,
  },
  data() {
    return {
      editing: { name: false, address: false },
      currentSharedSite: {
        name: "",
        address: "",
      },
      mdiPencil,
      originalSharedSite: {},
    };
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.sharedSiteEditorForm.validate();
      if (!success) {
        return;
      }

      const { selectedMapServiceId, objectId, currentSharedSite, globalId } =
        this;
      let site;
      try {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/shared_sites/${selectedMapServiceId}/${objectId}`
        );
        site = results;
      } finally {
        const { name, address } = currentSharedSite;
        if (site) {
          await axiosWithRegularAuth.put(
            `${APIURL}/shared_sites/${site.site_id}`,
            {
              name,
              active: true,
              address,
            }
          );
        } else {
          await axiosWithRegularAuth.post(`${APIURL}/shared_sites`, {
            map_service_id: selectedMapServiceId,
            feature_id: objectId,
            name,
            active: true,
            address,
            global_id: globalId,
          });
        }
        this.editing = { name: false, address: false };
        this.$emit("edited-site");
      }
    },
  },
  watch: {
    sharedSite: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.currentSharedSite = { ...val };
          this.originalSharedSite = { ...val };
        }
      },
    },
  },
};
</script>

<style scoped>
.small {
  font-size: 12px;
}
</style>
