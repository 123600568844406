<template>
  <section class="py-2 px-5 background">
    <template>
      <div style="padding: 3px" id="siteContactsBtn">
        <v-btn
          text
          color="#00A9F4"
          class="px-0 mx-0 d-flex align-center"
          @click="checkSharedSiteAndShowSiteContactsDialog"
        >
          <v-icon color="#00A9F4" class="mr-1">
            {{ mdiMapMarkerAccount }}
          </v-icon>
          Site Contacts
        </v-btn>
      </div>

      <v-btn
        text
        color="#00A9F4"
        @click="createPublicLink"
        v-if="!sharedSiteUrlId"
        class="px-1"
      >
        <v-icon class="mr-1">
          {{ mdiLinkPlus }}
        </v-icon>
        Create Public Link
      </v-btn>
      <section class="d-flex align-center" v-else>
        <v-btn
          text
          :to="`/${sharedSiteUrlId}`"
          target="_blank"
          class="px-1 mx-0"
        >
          <v-icon color="#00A9F4" class="mr-1">{{ mdiLink }}</v-icon>
          <div style="color: #00a9f4" class="mr-1">Public Site Link</div>
          <v-icon color="#00A9F4">{{ mdiOpenInNew }}</v-icon>
        </v-btn>

        <v-btn
          color="#00A9F4"
          icon
          @click="copyLinkToClipboard(`${FRONT_END_URL}/${sharedSiteUrlId}`)"
        >
          <v-icon color="#00A9F4">{{ mdiContentCopy }}</v-icon>
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="#737373">
              <v-icon color="#00A9F4">{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0 my-0">
            <v-list-item>
              <v-btn
                text
                class="px-0 text"
                @click="inactivateLink(publicSite.public_site_id)"
              >
                <v-icon class="mr-2" color="#00A9F4">
                  {{ mdiLinkOff }}
                </v-icon>
                Remove Sharing
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </section>

      <SiteContactsDialog
        v-if="showSiteContactsDialog"
        :showSiteContactsDialog="showSiteContactsDialog"
        :selectedMapServiceId="selectedMapServiceId"
        :objectId="objectId"
        :sharedSite="sharedSite"
        @site-contacts-dialog-close="showSiteContactsDialog = false"
      />

      <SiteNameDialog
        v-if="showSiteNameDialog"
        :showSiteNameDialog="showSiteNameDialog"
        :selectedMapServiceId="selectedMapServiceId"
        :objectId="objectId"
        @site-name-saved="onSiteNameSaved"
        @close="showSiteNameDialog = false"
      />
    </template>
  </section>
</template>

<script>
import {
  mdiLinkPlus,
  mdiLink,
  mdiOpenInNew,
  mdiContentCopy,
  mdiLinkOff,
  mdiDotsVertical,
  mdiMapMarkerAccount,
} from "@mdi/js";
import SiteContactsDialog from "@/components/app/share-public-site/SiteContactsDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import SiteNameDialog from "@/components/app/share-public-site/SiteNameDialog";
import { db } from "@/mixins/utilisync-db";

const APIURL = process.env.VUE_APP_API_URL;
const FRONT_END_URL = process.env.VUE_APP_FRONT_END_URL;

export default {
  name: "SharePublicSite",
  components: { SiteContactsDialog, SiteNameDialog },
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    attributes: Object,
    sharedSite: Object,
    globalId: String,
  },
  data() {
    return {
      mdiLinkPlus,
      mdiLink,
      mdiOpenInNew,
      mdiContentCopy,
      mdiLinkOff,
      mdiDotsVertical,
      mdiMapMarkerAccount,
      showSiteContactsDialog: false,
      showAddSiteContactDialog: false,
      showEditSiteContactDialog: false,
      selectedSiteId: undefined,
      currentSharedSite: {},
      FRONT_END_URL,
      publicSite: {},
      showSiteNameDialog: false,
      sharedSiteUrlId: "",
      openingSharedSiteDialog: false,
    };
  },
  methods: {
    async copyLinkToClipboard(publicSiteLink) {
      await navigator.clipboard.writeText(publicSiteLink);
    },
    async inactivateLink(publicSiteId) {
      await axiosWithRegularAuth.delete(
        `${APIURL}/public_site/${publicSiteId}`
      );
      this.publicSite = {};
      this.sharedSiteUrlId = "";
    },
    async getPublicSiteByUrlId(urlId) {
      try {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/public_site_external/${urlId}/exists`
        );
        return results;
      } catch (error) {
        return false;
      }
    },
    async getSharedSite() {
      if (!navigator.onLine) {
        return;
      }
      const { selectedMapServiceId, objectId } = this;
      if (!selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/public_site/map_service/${selectedMapServiceId}/feature/${objectId}`
      );
      this.sharedSiteUrlId = results?.url_id;
      this.publicSite = results;
    },
    async createPublicLink() {
      if (!navigator.onLine) {
        return;
      }
      const { selectedMapServiceId, objectId } = this;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/shared_sites/${selectedMapServiceId}/${objectId}`
      );
      this.currentSharedSite = { ...results };
      if (!this.currentSharedSite?.name) {
        this.showSiteNameDialog = true;
        return;
      }

      if (!this.sharedSiteUrlId) {
        const { selectedMapServiceId, objectId } = this;
        const {
          data: { results: urlId },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/public_site/generate_url_id`,
          {
            map_service_id: selectedMapServiceId,
            feature_id: objectId,
          }
        );
        const { globalId } = this;
        await axiosWithRegularAuth.post(
          `${APIURL}/public_site/map_service/${this.selectedMapServiceId}/feature/${this.objectId}`,
          {
            url_id: urlId,
            global_id: globalId,
          }
        );
        await this.getSharedSite();
        this.$emit("public-link-created");
      }
    },
    async checkSharedSiteAndShowSiteContactsDialog() {
      const { selectedMapServiceId, objectId } = this;
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();

      if (!selectedMapService?.site_enabled) {
        return;
      }

      let site;
      try {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/shared_sites/${selectedMapServiceId}/${objectId}`
        );
        site = results;
      } finally {
        if (site?.name) {
          this.showSiteContactsDialog = true;
        } else {
          this.showSiteNameDialog = true;
          this.openingSharedSiteDialog = true;
        }
      }
    },
    async onSiteNameSaved() {
      this.showSiteNameDialog = false;
      if (this.openingSharedSiteDialog) {
        this.showSiteContactsDialog = true;
      } else {
        await this.createPublicLink();
      }
      this.$emit("site-name-saved");
    },
  },
  async beforeMount() {
    this.currentSharedSite = { ...this.sharedSite };
    await this.getSharedSite();
  },
  watch: {
    sharedSite: {
      deep: true,
      handler(val) {
        this.currentSharedSite = { ...val };
      },
    },
    selectedMapServiceId() {
      this.getSharedSite();
    },
    objectId() {
      this.getSharedSite();
    },
  },
};
</script>

<style scoped>
.text {
  color: #00a9f4;
}
</style>
