var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-around py-0 my-0",
      staticStyle: { "background-color": "#00a9f4" }
    },
    [
      _c(
        "div",
        {
          directives: [{ name: "ripple", rawName: "v-ripple" }],
          staticClass: "tab tab-button text-center cursor-pointer pt-3",
          attrs: { id: "tasksBtn" },
          on: {
            click: function($event) {
              return _vm.$emit("gis-info-button-clicked", 0)
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "orange",
                    content: _vm.tasksLength,
                    overlap: "",
                    value: _vm.tasksLength > 0
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(_vm._s(_vm.mdiFormatListChecks) + " ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "white--text text-body-2 pt-1" }, [
            _vm._v("Tasks")
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [{ name: "ripple", rawName: "v-ripple" }],
          staticClass: "tab tab-button text-center cursor-pointer pt-3",
          attrs: { id: "logBtn" },
          on: {
            click: function($event) {
              return _vm.$emit("gis-info-button-clicked", 1)
            }
          }
        },
        [
          _c("v-icon", { attrs: { color: "white" } }, [
            _vm._v(_vm._s(_vm.mdiViewList))
          ]),
          _c("p", { staticClass: "white--text text-body-2 pt-1" }, [
            _vm._v("Log")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [{ name: "ripple", rawName: "v-ripple" }],
          staticClass: "tab tab-button text-center cursor-pointer pt-3",
          attrs: { id: "docsBtn" },
          on: {
            click: function($event) {
              return _vm.$emit("gis-info-button-clicked", 2)
            }
          }
        },
        [
          _c("v-icon", { attrs: { color: "white" } }, [
            _vm._v(_vm._s(_vm.mdiFile))
          ]),
          _c("p", { staticClass: "white--text text-body-2 pt-1" }, [
            _vm._v("Docs")
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }