<template>
  <v-dialog :value="showAddSiteContactDialog" persistent max-width="600px">
    <v-card id="addSiteContactDialog">
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <h3 class="font-weight-medium">Add Site Contact</h3>
        <v-spacer />
        <v-btn icon @click="$emit('add-site-contacts-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3" v-if="!addNewSiteContact">
        <v-combobox
          style="max-width: 300px"
          v-model="selectedContact"
          :items="filteredContacts"
          item-value="site_contact_id"
          hide-details
          label="Select from Existing Contacts"
          :append-icon="mdiMagnify"
          :search-input.sync="searchContact"
          no-filter
          @change="onContactAutocompleteChange"
        >
          <template #item="{ item }">
            <section>
              <div>{{ item.f_name }} {{ item.l_name }}</div>
              <div class="caption">{{ item.email }}</div>
            </section>
          </template>

          <template #selection="{ item }">
            <section>
              <div>{{ item.f_name }} {{ item.l_name }}</div>
              <div class="caption">{{ item.email }}</div>
            </section>
          </template>
        </v-combobox>

        <hr class="my-3" />

        <v-btn
          text
          class="d-flex align-center px-0"
          @click="addNewSiteContact = true"
          color="#00A9F4"
          id="newSiteContactBtn"
        >
          <v-icon>
            {{ mdiPlus }}
          </v-icon>
          New Site Contact
        </v-btn>
      </v-card-text>
      <validation-observer
        slim
        ref="siteContactForm"
        v-slot="{ valid: isFormValid }"
        v-else
      >
        <form @submit.prevent="submit">
          <v-card-text class="pt-3">
            <validation-provider
              :rules="{ required: true, email: true }"
              v-slot="{ errors, valid }"
              name="Email"
            >
              <v-text-field
                v-model="siteContact.email"
                class="flex-grow-1"
                label="Email *"
                hide-details
                :error-messages="errors"
                :success="valid"
                @blur="onEmailBlur"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="First Name"
            >
              <v-text-field
                v-model="siteContact.fName"
                class="flex-grow-1"
                label="First Name *"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="Last Name"
            >
              <v-text-field
                v-model="siteContact.lName"
                class="flex-grow-1"
                label="Last Name *"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Title">
              <v-text-field
                v-model="siteContact.title"
                class="flex-grow-1"
                label="Title"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Company">
              <v-text-field
                v-model="siteContact.company"
                class="flex-grow-1"
                label="Company"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{
                regex: /(\d{3}-?\s?\d{3}-?\s?\d{4}\s?)?(ext. \d{4})?/,
              }"
              v-slot="{ errors, valid }"
              name="Phone Number"
            >
              <v-text-field
                v-model="siteContact.phoneNumber"
                class="flex-grow-1"
                label="Phone Number"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <v-switch
              v-model="siteContact.showContactOnPublicSite"
              label="Show Contact on Public Site"
            ></v-switch>
          </v-card-text>

          <v-card-actions class="d-flex justify-end pa-5">
            <v-btn
              :disabled="!isFormValid || emailAlreadyLinked"
              type="submit"
              color="#3F51B5"
              :dark="isFormValid && !emailAlreadyLinked"
              id="createSiteContactBtn"
            >
              Create Site Contact
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>

    <ContactAlreadyExistsDialog
      v-if="showContactAlreadyExistsDialog"
      :showContactAlreadyExistsDialog="showContactAlreadyExistsDialog"
      :siteContact="siteContact"
      @cancel="showContactAlreadyExistsDialog = false"
      @add-site-contact="autoFillExistingContact()"
    />

    <ContactAlreadyLinkedDialog
      v-if="showContactAlreadyLinkedDialog"
      :showContactAlreadyLinkedDialog="showContactAlreadyLinkedDialog"
      @close="
        showContactAlreadyLinkedDialog = false;
        resetSiteContact();
      "
    />
  </v-dialog>
</template>

<script>
import { mdiClose, mdiPlus, mdiMagnify } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import ContactAlreadyLinkedDialog from "@/components/app/share-public-site/site-contacts-dialog/add-site-contact-dialog/ContactAlreadyLinkedDialog.vue";
import ContactAlreadyExistsDialog from "@/components/app/share-public-site/site-contacts-dialog/add-site-contact-dialog/ContactAlreadyExistsDialog.vue";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddSiteContactDialog",
  props: {
    showAddSiteContactDialog: Boolean,
    sharedSite: Object,
    sharedSiteContacts: Array,
  },
  components: { ContactAlreadyLinkedDialog, ContactAlreadyExistsDialog },
  data() {
    return {
      mdiClose,
      mdiPlus,
      mdiMagnify,
      siteContact: {
        email: "",
        fName: "",
        lName: "",
        title: "",
        company: "",
        phoneNumber: "",
        showContactOnPublicSite: false,
      },
      addNewSiteContact: false,
      selectedContact: undefined,
      contacts: [],
      searchContact: undefined,
      showContactAlreadyLinkedDialog: false,
      showContactAlreadyExistsDialog: false,
    };
  },
  computed: {
    emailAlreadyLinked() {
      const { email } = this.siteContact;
      const { feature_id: featureId, map_service_id: mapServiceId } =
        this.sharedSite;
      return Boolean(
        this.sharedSiteContacts.find((s) => {
          return (
            s.email === email &&
            s.feature_id === featureId &&
            s.map_service_id === mapServiceId
          );
        })
      );
    },
    filteredContacts() {
      const { searchContact } = this;
      if (!searchContact) {
        return [];
      }
      return this.contacts.filter((c) => {
        return (
          c.email.includes(searchContact) ||
          c.f_name.includes(searchContact) ||
          c.l_name.includes(searchContact)
        );
      });
    },
  },
  methods: {
    resetSiteContact() {
      this.siteContact = {
        email: "",
        fName: "",
        lName: "",
        title: "",
        company: "",
        phoneNumber: "",
        showContactOnPublicSite: false,
      };
    },
    async autoFillExistingContact() {
      this.showContactAlreadyExistsDialog = false;
      const contact = this.contacts.find(
        (c) => c.email === this.siteContact.email
      );
      const siteContactId = contact.site_contact_id;
      const { site_id: siteId } = this.sharedSite;
      const { showContactOnPublicSite } = this.siteContact;
      try {
        await axiosWithRegularAuth.post(`${APIURL}/shared_site_contacts`, {
          site_contact_id: siteContactId,
          site_id: siteId,
          show_contact_on_public_page: showContactOnPublicSite,
        });
        await axiosWithRegularAuth.post(
          `${APIURL}/shared_site_contacts/resend_invite`,
          {
            site_contact_id: siteContactId,
            site_id: siteId,
          }
        );
        this.$emit("site-added");
      } catch (error) {
        this.showContactAlreadyLinkedDialog = true;
      }
    },
    onEmailBlur() {
      const contactExists = Boolean(
        this.contacts.find((c) => c.email === this.siteContact.email)
      );
      if (contactExists) {
        this.showContactAlreadyExistsDialog = true;
      }
    },
    async addSiteContact() {
      const success = await this.$refs.siteContactForm.validate();
      if (!success) {
        return;
      }

      const contact = this.contacts.find(
        (c) => c.email === this.siteContact.email
      );
      const contactExists = Boolean(contact);

      const { site_id: siteId } = this.sharedSite;
      const {
        email,
        fName,
        lName,
        title,
        company,
        showContactOnPublicSite,
        phoneNumber,
      } = this.siteContact;

      let siteContactId;
      if (!contactExists) {
        const {
          data: { results: contact },
        } = await axiosWithRegularAuth.post(`${APIURL}/contacts`, {
          email,
          f_name: fName,
          l_name: lName,
          title,
          company,
          phone_no: phoneNumber || null,
          show_contact_on_public_page: showContactOnPublicSite,
        });
        siteContactId = contact.site_contact_id;
      } else {
        siteContactId = contact.site_contact_id;
      }

      await axiosWithRegularAuth.post(`${APIURL}/shared_site_contacts`, {
        site_contact_id: siteContactId,
        site_id: siteId,
        show_contact_on_public_page: showContactOnPublicSite,
      });
      await axiosWithRegularAuth.post(
        `${APIURL}/shared_site_contacts/resend_invite`,
        {
          site_contact_id: siteContactId,
          site_id: siteId,
        }
      );
      this.$emit("site-added");
    },
    async updateSiteContact() {
      const success = await this.$refs.siteContactForm.validate();
      if (!success) {
        return;
      }
      const { email, fName, lName, title, company, showContactOnPublicSite } =
        this.siteContact;
      await axiosWithRegularAuth.put(
        `${APIURL}/shared_sites/${this.selectedSiteId}`,
        {
          email,
          f_name: fName,
          l_name: lName,
          title,
          company,
          show_contact_on_public_page: showContactOnPublicSite,
        }
      );
      this.$emit("site-updated");
    },
    async submit() {
      await this.addSiteContact();
    },
    async getContacts() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/contacts`);
      this.contacts = results;
    },
    async onContactAutocompleteChange() {
      try {
        const { sharedSite, selectedContact } = this;
        await axiosWithRegularAuth.post(`${APIURL}/shared_site_contacts`, {
          site_contact_id: selectedContact.site_contact_id,
          site_id: sharedSite.site_id,
          show_contact_on_public_page: false,
        });
        await axiosWithRegularAuth.post(
          `${APIURL}/shared_site_contacts/resend_invite`,
          {
            site_contact_id: selectedContact.site_contact_id,
            site_id: sharedSite.site_id,
          }
        );
        this.$emit("site-added");
      } catch (error) {
        this.showContactAlreadyLinkedDialog = true;
      }
    },
  },
  async beforeMount() {
    await this.getContacts();
  },
};
</script>
