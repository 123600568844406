<template>
  <div :class="{ background: inRightPane }">
    <section
      :style="contentHeight"
      :class="inExpandInfoDialog ? 'overflow-y-auto' : undefined"
    >
      <template v-if="['U', 'F'].includes(selectedMapServiceType)">
        <section
          class="
            d-flex
            align-center
            justify-space-between
            px-5
            header-background
          "
        >
          <div class="caption"><b>UtiliSync Fields</b></div>

          <v-btn text @click="reload" class="px-0 py-3 my-0" height="0">
            <v-icon size="15">{{ mdiRefresh }}</v-icon>
            <span class="ml-1 caption">Reload</span>
          </v-btn>
        </section>

        <div v-for="field of firstUtiliSyncFieldGroupFields" :key="field.id">
          <div
            class="d-flex gap py-1"
            v-if="
              getUtiliSyncMapServiceFieldType(field) ===
              UTILISYNC_FIELD_TYPES.FIELD
            "
            @click="editUtiliSyncField(field)"
            :class="{
              'cursor-pointer': isUtiliSyncFieldsEditable(field.fieldId),
            }"
          >
            <div class="d-flex field-name caption pl-5 pr-1">
              <div v-if="getGisDataField(field.fieldId)">
                <b class="caption font-weight-bold break-word reduce-height">
                  {{ getGisDataField(field.fieldId).name }}
                </b>
              </div>
            </div>

            <section
              v-if="!editingUtiliSyncField[field.fieldId]"
              class="flex-grow-1"
            >
              <div
                v-if="!isRichTextField(field.value)"
                class="break-word field-value"
              >
                {{
                  getUtiliSyncFieldValueLabel(
                    getGisDataField(field.fieldId),
                    field.value
                  )
                }}
              </div>
              <div
                v-else
                class="ql-editor break-word field-value"
                v-html="field.value"
              ></div>
            </section>
            <section v-else>
              <v-switch
                v-if="
                  getUtiliSyncFieldType(field.fieldId) === 'string' &&
                  !isUtiliSyncFieldRefField(getGisDataField(field.fieldId))
                "
                v-model="richTextUtiliSyncField[field.fieldId]"
                label="Rich Text"
                hide-details
                class="py-0 my-0"
              ></v-switch>

              <GisFieldInput
                :richTextField="richTextUtiliSyncField[field.fieldId]"
                :fieldType="getUtiliSyncFieldType(field.fieldId)"
                :gisDataFieldOptions="
                  getGisDataFieldOptionsByGisDataFieldId(field.fieldId)
                "
                @cancel-edit="$set(editingUtiliSyncField, field.fieldId, false)"
                v-model="field.value"
                @input="
                  onCancelUtiliSyncFieldEdit(field);
                  saveUtiliSyncField(
                    field.fieldId,
                    field.gisDataValueId,
                    $event
                  );
                "
              />
            </section>
          </div>
          <template
            v-else-if="
              getUtiliSyncMapServiceFieldType(field) ===
              UTILISYNC_FIELD_TYPES.ID
            "
          >
            <div v-if="field.fieldId === 'objectId'" class="d-flex gap py-1">
              <div class="caption field-name pl-5 pr-1"><b>Feature ID</b></div>
              <div class="field-value">{{ objectId }}</div>
            </div>
            <div
              v-else-if="field.fieldId === 'globalId'"
              class="d-flex gap py-1"
            >
              <div class="caption field-name pl-5 pr-1"><b>Global ID</b></div>
              <div class="field-value">{{ globalId }}</div>
            </div>
          </template>
        </div>

        <v-expansion-panels flat multiple v-model="openUtiliSyncFieldPanels">
          <v-expansion-panel
            :value="index"
            :class="{ background: inRightPane }"
            v-for="(
              group, index
            ) of selectedMapServiceUtiliSyncFieldGroupsToEdit"
            :key="group.id"
            class="nav-border-top"
          >
            <v-expansion-panel-header
              class="text-uppercase px-5 py-1 my-0 expansion-panel-header"
              :class="{ background: inRightPane }"
            >
              <b class="header">{{ group.sectionHeader.sectionLabel }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="py-0"
              :class="{ background: inRightPane }"
            >
              <section class="ma-0" :class="{ background: inRightPane }">
                <div
                  v-for="field of group.fields"
                  :key="field.id"
                  class="py-1 ma-0"
                >
                  <div
                    :class="{
                      background: inRightPane,
                      'cursor-pointer': isUtiliSyncFieldsEditable(
                        field.fieldId
                      ),
                    }"
                    v-if="
                      getUtiliSyncMapServiceFieldType(field) ===
                      UTILISYNC_FIELD_TYPES.FIELD
                    "
                    class="d-flex gap"
                    @click="editUtiliSyncField(field)"
                  >
                    <div class="d-flex gap">
                      <section class="d-flex field-name pl-5 pr-1">
                        <div v-if="getGisDataField(field.fieldId)">
                          <b
                            class="
                              caption
                              font-weight-bold
                              break-word
                              reduce-height
                            "
                          >
                            {{ getGisDataField(field.fieldId).name }}
                          </b>
                        </div>
                      </section>

                      <section v-if="!editingUtiliSyncField[field.fieldId]">
                        <div
                          v-if="!isRichTextField(field.value)"
                          class="break-word field-value"
                        >
                          {{
                            getUtiliSyncFieldValueLabel(
                              getGisDataField(field.fieldId),
                              field.value
                            )
                          }}
                        </div>
                        <div
                          v-else
                          class="ql-editor break-word field-value"
                          v-html="field.value"
                        ></div>
                      </section>
                      <section v-else>
                        <v-switch
                          v-if="
                            getUtiliSyncFieldType(field.fieldId) === 'string' &&
                            !isUtiliSyncFieldRefField(
                              getGisDataField(field.fieldId)
                            )
                          "
                          v-model="richTextUtiliSyncField[field.fieldId]"
                          label="Rich Text"
                          hide-details
                          class="py-0 my-0"
                        ></v-switch>

                        <GisFieldInput
                          :richTextField="richTextUtiliSyncField[field.fieldId]"
                          :fieldType="getUtiliSyncFieldType(field.fieldId)"
                          :gisDataFieldOptions="
                            getGisDataFieldOptionsByGisDataFieldId(
                              field.fieldId
                            )
                          "
                          @cancel-edit="
                            $set(editingUtiliSyncField, field.fieldId, false)
                          "
                          v-model="field.value"
                          @input="
                            onCancelUtiliSyncFieldEdit(field);
                            saveUtiliSyncField(
                              field.fieldId,
                              field.gisDataValueId,
                              $event
                            );
                          "
                        />
                      </section>
                    </div>
                  </div>
                  <template
                    v-else-if="
                      getUtiliSyncMapServiceFieldType(field) ===
                      UTILISYNC_FIELD_TYPES.ID
                    "
                  >
                    <div v-if="field.fieldId === 'objectId'" class="d-flex gap">
                      <div class="caption field-name pl-5 pr-1">
                        <b>Feature ID</b>
                      </div>
                      <div class="field-value">
                        {{ objectId }}
                      </div>
                    </div>
                    <div
                      v-else-if="field.fieldId === 'globalId'"
                      class="d-flex gap"
                    >
                      <div class="caption field-name pl-5 pr-1">
                        <b>Global ID</b>
                      </div>
                      <div class="field-value">
                        {{ globalId }}
                      </div>
                    </div>
                  </template>
                </div>
              </section>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <section
        v-if="notUtiliSyncLayer"
        class="d-flex align-center justify-space-between px-5 header-background"
      >
        <div class="caption"><b>GIS Fields</b></div>

        <v-btn
          text
          @click="loadLatestGisData"
          class="px-0 py-3 my-0"
          height="0"
        >
          <v-icon size="15">{{ mdiRefresh }}</v-icon>
          <span class="ml-1 caption">Reload</span>
        </v-btn>
      </section>

      <template v-if="notUtiliSyncLayer">
        <div v-for="field of firstGisFieldGroupFields" :key="field.id">
          <div
            class="d-flex gap"
            v-if="getGisMapServiceFieldType(field) === GIS_FIELD_TYPES.FIELD"
            @click="editGisField(field)"
            :class="{ 'cursor-pointer': isGisFieldsEditable(field.fieldId) }"
          >
            <div class="caption field-name pl-5 pr-1">
              <b class="caption font-weight-bold break-word reduce-height">
                {{ field.labels.join(", ") }}
              </b>
            </div>

            <section v-if="editingGisField[field.fieldId]">
              <v-switch
                v-if="
                  getEsriFieldType(field.fieldId) === 'esriFieldTypeString' &&
                  !Array.isArray(getCodeValues(field.fieldId)) &&
                  !isEsriFieldRefField(field.fieldId)
                "
                v-model="richTextField[field.fieldId]"
                label="Rich Text"
                hide-details
                class="py-0 my-0"
              ></v-switch>

              <FeatureItemGisFieldInput
                :richTextField="richTextField[field.fieldId]"
                :fieldType="getEsriFieldType(field.fieldId)"
                :featureItemFields="featureItemFields"
                :gisInfoAttributeKey="field.fieldId"
                @cancel-edit="onCancelGisFieldEdit(field)"
                v-model="field.value"
                @input="saveEsriField(field.fieldId, $event)"
              />
            </section>
            <section v-else>
              <div
                v-if="!richTextField[field.fieldId]"
                class="break-word field-value"
              >
                <div
                  v-if="
                    getEsriFieldType(field.fieldId) === 'esriFieldTypeDate' &&
                    field.value !== null
                  "
                >
                  {{ new Date(field.value) | formatUtcDate }}
                </div>
                <div v-else class="field-value">
                  <template v-if="!Array.isArray(getCodeValues(field.fieldId))">
                    {{ field.value }}
                  </template>
                  <template v-else>
                    {{ getNameForCodeValue(field.fieldId, field.value) }}
                  </template>
                </div>
              </div>

              <div
                v-else
                v-html="field.value"
                class="break-word field-value"
              ></div>
            </section>
          </div>
        </div>
      </template>

      <v-expansion-panels
        flat
        multiple
        v-if="notUtiliSyncLayer"
        v-model="openGisFieldPanels"
      >
        <v-expansion-panel
          :value="index"
          :class="{ background: inRightPane }"
          v-for="(group, index) of selectedMapServiceGisFieldGroupsToEdit"
          :key="group.id"
          class="nav-border-top"
        >
          <v-expansion-panel-header
            class="
              text-uppercase
              nav-border-top
              my-0
              py-1
              px-5
              expansion-panel-header
            "
            :class="{ background: inRightPane }"
          >
            <b class="header">{{ group.sectionHeader.sectionLabel }}</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="my-0 py-0"
            :class="{ background: inRightPane }"
          >
            <div
              v-for="field of group.fields"
              :key="field.id"
              class="ma-0 py-1"
            >
              <div
                class="d-flex gap"
                v-if="
                  getGisMapServiceFieldType(field) === GIS_FIELD_TYPES.FIELD
                "
                @click="editGisField(field)"
                :class="{
                  'cursor-pointer': isGisFieldsEditable(field.fieldId),
                }"
              >
                <div class="caption field-name pl-5 pr-1">
                  <b class="caption font-weight-bold break-word reduce-height">
                    {{ field.labels.join(", ") }}
                  </b>
                </div>

                <section v-if="editingGisField[field.fieldId]">
                  <v-switch
                    v-if="
                      getEsriFieldType(field.fieldId) ===
                        'esriFieldTypeString' &&
                      !Array.isArray(getCodeValues(field.fieldId)) &&
                      !isEsriFieldRefField(field.fieldId)
                    "
                    v-model="richTextField[field.fieldId]"
                    label="Rich Text"
                    hide-details
                    class="py-0 my-0"
                  ></v-switch>

                  <FeatureItemGisFieldInput
                    :richTextField="richTextField[field.fieldId]"
                    :fieldType="getEsriFieldType(field.fieldId)"
                    :featureItemFields="featureItemFields"
                    :gisInfoAttributeKey="field.fieldId"
                    @cancel-edit="onCancelGisFieldEdit(field)"
                    v-model="field.value"
                    @input="saveEsriField(field.fieldId, $event)"
                  />
                </section>
                <section v-else>
                  <div
                    v-if="!richTextField[field.fieldId]"
                    class="break-word field-value"
                  >
                    <div
                      v-if="
                        getEsriFieldType(field.fieldId) ===
                          'esriFieldTypeDate' && field.value !== null
                      "
                    >
                      {{ new Date(field.value) | formatUtcDate }}
                    </div>
                    <div v-else class="field-value">
                      <template
                        v-if="!Array.isArray(getCodeValues(field.fieldId))"
                      >
                        {{ field.value }}
                      </template>
                      <template v-else>
                        {{ getNameForCodeValue(field.fieldId, field.value) }}
                      </template>
                    </div>
                  </div>

                  <div
                    v-else
                    v-html="field.value"
                    class="break-word field-value"
                  ></div>
                </section>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <ApplyEditResultDialog
        :showApplyEditResultDialog="showApplyEditResultDialog"
        :errorMessage="updateResultErrorMessage"
        @close="showApplyEditResultDialog = false"
      />
    </section>
  </div>
</template>

<script>
import { db } from "@/mixins/utilisync-db";
import { mdiPencil, mdiCloudOffOutline, mdiRefresh } from "@mdi/js";
import GisFieldInput from "@/components/shared/gis-info/GisFieldInput";
import FeatureItemGisFieldInput from "@/components/shared/gis-info/FeatureItemGisFieldInput";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import ApplyEditResultDialog from "@/components/app/ApplyEditResultDialog";
import sleep from "@/mixins/sleep";
import { mapMutations } from "vuex";
import { sortBy, cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

const APIURL = process.env.VUE_APP_API_URL;
const UTILISYNC_FIELD_TYPES = {
  ID: "id",
  FIELD: "field",
  SECTION_LABEL: "section-label",
};
const GIS_FIELD_TYPES = {
  ID: "id",
  FIELD: "field",
  SECTION_LABEL: "section-label",
};

export default {
  name: "SortedGisInfo",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    inRightPane: Boolean,
    inExpandInfoDialog: Boolean,
    gisInfoAttributes: Object,
  },
  components: {
    GisFieldInput,
    FeatureItemGisFieldInput,
    ApplyEditResultDialog,
  },
  mixins: [networkStatusMixin, downloadDataMixin],
  data() {
    return {
      openUtiliSyncFieldPanels: [],
      openGisFieldPanels: [],
      loadingGisInfo: true,
      gisDataFields: [],
      gisDataValues: [],
      mapServices: [],
      mdiPencil,
      mdiCloudOffOutline,
      mdiRefresh,
      editingUtiliSyncField: {},
      editingGisField: {},
      richTextField: {},
      richTextUtiliSyncField: {},
      gisDataFieldOptions: [],
      siteInfoHeight: window.innerHeight - 300,
      loadingGisFields: true,
      gettingLatestUtiliSyncFields: navigator.onLine,
      updateResultErrorMessage: "",
      showApplyEditResultDialog: false,
      latestFeatureItemAttributes: {},
      latestGisDataLoaded: false,
      UTILISYNC_FIELD_TYPES,
      GIS_FIELD_TYPES,
      selectedMapServiceUtiliSyncFieldGroupsToEdit: [],
      selectedMapServiceGisFieldGroupsToEdit: [],
      firstUtiliSyncFieldGroupFields: [],
      firstGisFieldGroupFields: [],
      featureItemFields: [],
    };
  },
  computed: {
    selectedLayerGisFields() {
      const {
        featureItemFields,
        latestFeatureItemAttributes,
        gisInfoAttributes,
      } = this;
      if (!Array.isArray(featureItemFields) || featureItemFields.length === 0) {
        if (
          typeof gisInfoAttributes === "object" &&
          gisInfoAttributes !== null
        ) {
          const gisFields = Object.entries(gisInfoAttributes).map(
            ([fieldId, value]) => {
              const id = uuidv4();
              return { id, fieldId, visible: true, labels: [fieldId], value };
            }
          );
          return gisFields;
        }
        return [];
      }
      if (Array.isArray(this.selectedMapService?.display_gis_fields)) {
        const filteredAndMappedFields =
          this.selectedMapService?.display_gis_fields
            ?.filter((f) => f.visible)
            .map((f) => {
              const field = featureItemFields.find((lf) => {
                return lf.name === f.fieldId;
              });
              const { name: key } = field ?? {};
              const value = latestFeatureItemAttributes[key];
              return { ...f, value };
            });
        return sortBy(filteredAndMappedFields, ["order"]) ?? [];
      } else {
        return featureItemFields.map((f) => {
          const { name: fieldId, alias } = f;
          const id = uuidv4();
          const value = latestFeatureItemAttributes[fieldId];
          return { id, fieldId, visible: true, labels: [alias], value };
        });
      }
    },
    selectedLayerUtiliSyncFields() {
      if (Array.isArray(this.selectedMapService?.display_utilisync_fields)) {
        const filteredAndMappedFields =
          this.selectedMapService?.display_utilisync_fields
            ?.filter((f) => f.visible)
            .map((f) => {
              const { gis_data_value_id: gisDataValueId, value } =
                this.gisDataValues.find(
                  (g) =>
                    g.gis_data_field_id === f.fieldId &&
                    g.feature_id === this.objectId
                ) ?? {};
              return { ...f, gisDataValueId, value };
            });
        return sortBy(filteredAndMappedFields, ["order"]) ?? [];
      } else {
        const gisDataValueIds = this.gisDataValues.map(
          (g) => g.gis_data_field_id
        );
        const utiliSyncFields = this.gisDataFields
          .filter((g) => {
            return (
              g.map_service_id === this.selectedMapServiceId &&
              !gisDataValueIds.includes(this.objectId)
            );
          })
          .map((f) => {
            const { gis_data_field_id: fieldId } = f;
            const { gis_data_value_id: gisDataValueId, value } =
              this.gisDataValues.find((g) => {
                return (
                  g.gis_data_field_id === fieldId &&
                  g.feature_id === this.objectId
                );
              }) ?? {};
            const id = uuidv4();
            return {
              id,
              fieldId,
              visible: true,
              labels: [],
              gisDataValueId,
              value,
            };
          });
        const objectId = {
          id: uuidv4(),
          fieldId: "objectId",
          visible: true,
          labels: [],
          value: this.objectId,
        };
        const globalId = {
          id: uuidv4(),
          fieldId: "globalId",
          visible: true,
          labels: [],
          value: this.globalId,
        };
        return [objectId, globalId, ...utiliSyncFields];
      }
    },
    contentHeight() {
      if (this.inExpandInfoDialog) {
        return {
          height: this.$vuetify.breakpoint.mdAndUp
            ? `${this.siteInfoHeight}px`
            : "auto",
        };
      }
      return {};
    },
    computeDate(secondsFromEpoch) {
      return new Date(secondsFromEpoch * 1000);
    },
    selectedMapService() {
      return this.mapServices?.find(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
    },
    isViewOnlyUser() {
      try {
        const auth = JSON.parse(localStorage.getItem("auth"));
        return auth?.is_view_only_user;
      } catch (error) {
        return true;
      }
    },
    selectedMapServiceType() {
      return this.selectedMapService?.service_type;
    },
    notUtiliSyncLayer() {
      return this.selectedMapServiceType !== "U";
    },
    showGisFields() {
      const selectedMapService = this.mapServices.find?.(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      if (!selectedMapService) {
        return true;
      }
      return (
        (Boolean(localStorage.getItem("esri_token")) &&
          selectedMapService?.token_type === "AGOL") ||
        selectedMapService?.token_type === "NONE"
      );
    },
  },
  methods: {
    ...mapMutations(["setIsRedrawLayers"]),
    onCancelUtiliSyncFieldEdit(field) {
      this.$set(this.editingUtiliSyncField, field.fieldId, false);
    },
    onCancelGisFieldEdit(field) {
      this.$set(this.editingGisField, field.fieldId, false);
    },
    editGisField(field) {
      if (this.isGisFieldsEditable(field.fieldId)) {
        this.$set(this.editingGisField, field.fieldId, true);
      }
    },
    editUtiliSyncField(field) {
      if (this.isUtiliSyncFieldsEditable(field.fieldId)) {
        this.$set(this.editingUtiliSyncField, field.fieldId, true);
      }
    },
    getGisDataFieldOptionsByGisDataFieldId(gisDataFieldId) {
      return this.gisDataFieldOptions.filter(
        (o) => o.gis_data_field_id === gisDataFieldId
      );
    },
    getGisDataField(gisDataFieldId) {
      return this.gisDataFields.find(
        (f) => f.gis_data_field_id === gisDataFieldId
      );
    },
    getUtiliSyncMapServiceFieldType(field) {
      if (field?.fieldId) {
        if (["objectId", "globalId"].includes(field?.fieldId)) {
          return UTILISYNC_FIELD_TYPES.ID;
        } else {
          return UTILISYNC_FIELD_TYPES.FIELD;
        }
      } else if (field?.sectionLabel) {
        return UTILISYNC_FIELD_TYPES.SECTION_LABEL;
      }
    },
    getGisMapServiceFieldType(field) {
      if (field?.fieldId) {
        if (["objectId", "globalId"].includes(field?.fieldId)) {
          return GIS_FIELD_TYPES.ID;
        } else {
          return GIS_FIELD_TYPES.FIELD;
        }
      } else if (field?.sectionLabel) {
        return GIS_FIELD_TYPES.SECTION_LABEL;
      }
    },
    async reload() {
      this.editingGisField = {};
      this.editingUtiliSyncField = {};
      const { selectedMapServiceId } = this;
      await sleep(2000);
      this.setIsRedrawLayers({ selectedMapServiceId });
    },
    async getLatestUtiliSyncFields() {
      if (!navigator.onLine) {
        return;
      }
      this.gettingLatestUtiliSyncFields = true;
      await this.getDownloadSummary(true);
      await this.downloadGisDataFields();
      await this.downloadGisDataValues();
      await this.downloadUserDataValues(true);
      this.gettingLatestUtiliSyncFields = false;
      localStorage.setItem("last-updated", new Date().toString());
    },
    getUtiliSyncFieldValueLabel(utiliSyncField, value) {
      return (
        utiliSyncField?.gisDataFieldOptions?.find(
          (o) => o.value?.toString() === value?.toString()
        )?.label ?? value
      );
    },
    onResize() {
      this.siteInfoHeight = window.innerHeight - 300;
    },
    isUtiliSyncFieldRefField(utiliSyncField) {
      return (
        this.selectedMapService?.ref_field
          ?.toLowerCase()
          ?.replace(/[_ ]/g, "") ===
        utiliSyncField?.name?.toLowerCase()?.replace(/[_ ]/g, "")
      );
    },
    isEsriFieldRefField(esriFieldName) {
      return (
        this.selectedMapService?.ref_field
          ?.toLowerCase()
          ?.replace(/_ /g, "") ===
        esriFieldName?.toLowerCase()?.replace(/[_ ]/g, "")
      );
    },
    getCodeValues(gisInfoAttributeKey) {
      return this.featureItemFields?.find(
        (f) => f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
      )?.domain?.codedValues;
    },
    getNameForCodeValue(gisInfoAttributeKey, value) {
      return this.featureItemFields
        ?.find(
          (f) =>
            f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
        )
        ?.domain?.codedValues?.find?.((c) => c.code === value)?.name;
    },
    isRichTextField(inputValue) {
      return Boolean(inputValue?.match?.(/<[^>]*>/g));
    },
    getEsriFieldType(esriFieldKey) {
      const field = this.featureItemFields?.find(
        (f) => f.name === esriFieldKey || f.alias === esriFieldKey
      );
      return field?.type;
    },
    async saveEsriField(key, value) {
      const { objectId } = this;
      const fieldName = this.featureItemFields.find(
        (f) => f.name === key || f.alias === key
      )?.name;
      const objectIdFieldName = this.featureItemFields.find(
        (f) => f.type === "esriFieldTypeOID"
      )?.name;
      const formData = new FormData();
      formData.append("f", "json");
      formData.append("token", localStorage.getItem("esri_token"));
      formData.append(
        "updates",
        JSON.stringify([
          {
            attributes: {
              [objectIdFieldName ?? "OBJECTID"]: objectId,
              [fieldName]: value,
            },
          },
        ])
      );
      const selectedMapService = this.mapServices.find?.(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      const mapServiceUrl = selectedMapService?.service_url;
      const { data } = await axiosWithNoAuth.post(
        `${mapServiceUrl}/applyEdits`,
        formData
      );
      const [updateResult] = data?.updateResults ?? [];
      if (updateResult?.error) {
        this.updateResultErrorMessage = updateResult?.error?.description;
        this.showApplyEditResultDialog = true;
      } else {
        this.$set(this.editingGisField, key, false);
        this.$emit("esri-field-saved", this.selectedMapServiceId);
      }
    },
    async saveUtiliSyncField(gisDataFieldId, gisDataValueId, value) {
      const { objectId } = this;
      const [gisDataValue] = await db.gisDataValues
        .filter((g) => {
          return (
            g.gis_data_value_id === gisDataValueId && g.feature_id === objectId
          );
        })
        .toArray();
      if (!gisDataValue) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/gis_data_values/by_gis_data_field_id_and_feature_id`,
          {
            params: {
              gis_data_field_id: gisDataFieldId,
              feature_id: objectId,
            },
          }
        );

        if (!results) {
          const {
            data: { results: newGisDataValue },
          } = await axiosWithRegularAuth.post(
            `${APIURL}/gis_data_values/insert`,
            {
              gis_data_field_id: gisDataFieldId,
              feature_id: objectId,
              value,
            }
          );
          await db.gisDataValues.put(newGisDataValue);
        } else {
          await db.gisDataValues.put(results);
        }
      } else {
        await db.gisDataValues.put({ ...gisDataValue, value }, [
          gisDataValue.gis_data_value_id,
        ]);
        await axiosWithRegularAuth.put(`${APIURL}/gis_data_values`, {
          gis_data_value_id: gisDataValue?.gis_data_value_id,
          value,
        });
      }

      this.$emit("utilisync-field-saved", this.selectedMapServiceId);
    },
    getUtiliSyncFieldType(gisDataFieldId) {
      const field = this.gisDataFields.find(
        (g) => g.gis_data_field_id === gisDataFieldId
      );
      return field?.type;
    },
    isUtiliSyncFieldsEditable(fieldId) {
      if (this.isViewOnlyUser) {
        return false;
      }
      return !["objectid", "globalid"].includes(fieldId?.toLowerCase());
    },
    isGisFieldsEditable(key) {
      if (this.isViewOnlyUser) {
        return false;
      }
      return !["objectid", "globalid"].includes(key?.toLowerCase());
    },
    async getUtiliSyncFieldsAndValues() {
      this.gisDataValues = await db.gisDataValues.toCollection().toArray();
      this.gisDataFields = await db.gisDataFields.toCollection().toArray();
    },
    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    async getGisDataFieldOptions() {
      this.gisDataFieldOptions = await db.gisDataFieldOptions
        .toCollection()
        .toArray();
    },
    async loadLatestGisData() {
      this.loadingGisFields = true;
      const [results] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedMapServiceId)
        .toArray();
      if (results?.service_type !== "F") {
        return;
      }
      try {
        const mapServiceUrl = results.service_url;
        const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
          params: {
            objectids: this.objectId,
            outFields: "*",
            f: "json",
            token: localStorage.getItem("esri_token"),
          },
        });
        const { features, fields } = data;
        const [feature] = features ?? [];
        const featureItemAttributes = feature?.attributes ?? {};
        this.featureItemFields = fields;
        this.latestFeatureItemAttributes = featureItemAttributes;
        this.loadingGisFields = false;
        this.latestGisDataLoaded = true;
      } catch (error) {
        console.log(error);
      }
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
  },
  async beforeMount() {
    this.loadingGisFields = true;
    this.loadingGisInfo = true;
    this.onResize();
    window.addEventListener("resize", this.onResize);
    await this.getLatestUtiliSyncFields();
    this.getUtiliSyncFieldsAndValues();
    this.getMapServices();
    this.getGisDataFieldOptions();
    await this.loadLatestGisData();
    this.loadingGisFields = false;
  },
  watch: {
    globalId() {
      this.getUtiliSyncFieldsAndValues();
    },
    featureId() {
      this.getUtiliSyncFieldsAndValues();
    },
    selectedMapServiceId: {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        await this.getUtiliSyncFieldsAndValues();
      },
    },
    selectedLayerUtiliSyncFields: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        const sectionHeaders = val.filter(
          (v) =>
            this.getUtiliSyncMapServiceFieldType(v) ===
            UTILISYNC_FIELD_TYPES.SECTION_LABEL
        );
        const fields = val.filter(
          (v) =>
            this.getUtiliSyncMapServiceFieldType(v) !==
            UTILISYNC_FIELD_TYPES.SECTION_LABEL
        );
        const groups = sectionHeaders
          .map((sectionHeader, sectionHeaderIndex) => {
            const startIndex = val.findIndex((v) => v.id === sectionHeader.id);
            let endIndex = val.length - 1;
            const nextSectionHeader = sectionHeaders[sectionHeaderIndex + 1];
            if (nextSectionHeader) {
              endIndex = val.findIndex((v) => v.id === nextSectionHeader.id);
            }
            return {
              id: uuidv4(),
              sectionHeader,
              startIndex,
              endIndex,
              fields: [],
            };
          })
          .sort((a, b) => a.startIndex - b.startIndex);
        for (const field of fields) {
          const fieldIndex = val.findIndex((v) => v.id === field.id);
          const group = groups.find(
            (g) => fieldIndex >= g.startIndex && fieldIndex <= g.endIndex
          );
          if (group) {
            group.fields.push(field);
          }
        }
        const addedFieldIds = groups
          .map((g) => g.fields)
          .flat()
          .map((f) => f.id);
        const firstUtiliSyncFieldGroupFields = val
          .filter((f) => !addedFieldIds.includes(f.id))
          .sort((a, b) => a.order - b.order);
        this.firstUtiliSyncFieldGroupFields = firstUtiliSyncFieldGroupFields;
        this.selectedMapServiceUtiliSyncFieldGroupsToEdit = cloneDeep(groups);
        await this.$nextTick();
        this.openUtiliSyncFieldPanels = groups.map((_, i) => i);
      },
    },
    selectedLayerGisFields: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        const sectionHeaders = val.filter(
          (v) =>
            this.getGisMapServiceFieldType(v) === GIS_FIELD_TYPES.SECTION_LABEL
        );
        const fields = val.filter(
          (v) =>
            this.getGisMapServiceFieldType(v) !== GIS_FIELD_TYPES.SECTION_LABEL
        );
        const groups = sectionHeaders
          .map((sectionHeader, sectionHeaderIndex) => {
            const startIndex = val.findIndex((v) => v.id === sectionHeader.id);
            let endIndex = val.length - 1;
            const nextSectionHeader = sectionHeaders[sectionHeaderIndex + 1];
            if (nextSectionHeader) {
              endIndex = val.findIndex((v) => v.id === nextSectionHeader.id);
            }
            return {
              id: uuidv4(),
              sectionHeader,
              startIndex,
              endIndex,
              fields: [],
            };
          })
          .sort((a, b) => a.startIndex - b.startIndex);
        for (const field of fields) {
          const fieldIndex = val.findIndex((v) => v.id === field.id);
          const group = groups.find(
            (g) => fieldIndex >= g.startIndex && fieldIndex <= g.endIndex
          );
          if (group) {
            group.fields.push(field);
          }
        }
        const addedFieldIds = groups
          .map((g) => g.fields)
          .flat()
          .map((f) => f.id);
        const firstGisFieldGroupFields = val
          .filter((f) => !addedFieldIds.includes(f.id))
          .sort((a, b) => a.order - b.order);
        this.firstGisFieldGroupFields = firstGisFieldGroupFields;
        this.selectedMapServiceGisFieldGroupsToEdit = cloneDeep(groups);
        await this.$nextTick();
        this.openGisFieldPanels = groups.map((_, i) => i);
      },
    },
    selectedMapServiceUtiliSyncFieldGroupsToEdit: {
      deep: true,
      immediate: true,
      handler(val) {
        const fields = val.map((g) => g.fields).flat();
        for (const field of fields) {
          this.$set(
            this.richTextUtiliSyncField,
            field.fieldId,
            this.isRichTextField(field.value)
          );
        }
      },
    },
    selectedMapServiceGisFieldGroupsToEdit: {
      deep: true,
      immediate: true,
      handler(val) {
        const fields = val.map((g) => g.fields).flat();
        for (const field of fields) {
          this.$set(
            this.richTextField,
            field.fieldId,
            this.isRichTextField(field.value)
          );
        }
      },
    },
    firstUtiliSyncFieldGroupFields: {
      deep: true,
      immediate: true,
      handler(fields) {
        for (const field of fields) {
          this.$set(
            this.richTextUtiliSyncField,
            field.fieldId,
            this.isRichTextField(field.value)
          );
        }
      },
    },
    firstGisFieldGroupFields: {
      deep: true,
      immediate: true,
      handler(fields) {
        for (const field of fields) {
          this.$set(
            this.richTextUtiliSyncField,
            field.fieldId,
            this.isRichTextField(field.value)
          );
        }
      },
    },
    objectId: {
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.loadLatestGisData();
      },
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.gap {
  gap: 5px;
}

.ql-editor {
  margin: 0px;
  padding: 0px;
}

.field-name {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
}

.field-value {
  font-size: 15px;
}

.break-word {
  word-break: break-word;
}

.header-background {
  background: #e6e6e6;
}

.expansion-panel-header .v-expansion-panel-header {
  padding: 0px 2px;
  color: #626262;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset !important;
}

.header {
  color: #626262;
}

.reduce-height {
  display: inline-block;
  line-height: 1.25;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}
</style>
