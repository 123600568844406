<template>
  <v-dialog :value="showExpansionPanelDialog" fullscreen persistent>
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div
          class="d-flex justify-space-between align-center"
          style="font-size: 12px; width: 100%"
        >
          <div class="white--text">
            <div>
              {{ title }}
            </div>

            <div class="white--text">
              {{ subtitle }}
            </div>
          </div>
          <v-btn
            icon
            text
            @click="$emit('expand-info-panel-close')"
            id="collapsePanelBtn"
          >
            <v-icon color="white">
              {{ mdiArrowCollapse }}
            </v-icon>
          </v-btn>
        </div>
      </v-toolbar>

      <v-card-text class="pt-2" v-if="!isLoading">
        <v-row align="stretch">
          <v-col cols="12" md="4" class="d-flex flex-column">
            <v-card class="my-1" id="formsSection">
              <v-card-text>
                <TicketFormDefDropdown
                  :globalId="globalId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="objectId"
                  :selectedGisInfoObj="selectedGisInfoObj"
                  inExpandInfoDialog
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-1" id="siteInfoSection">
              <v-card-title class="font-weight-regular d-flex gap">
                <v-btn
                  fab
                  dark
                  color="#2193F6"
                  x-small
                  :ripple="false"
                  elevation="0"
                  class="disable-pointer"
                >
                  <v-icon>
                    {{ mdiGoogleMaps }}
                  </v-icon>
                </v-btn>
                <div>Site Information</div>
              </v-card-title>
              <v-card-text>
                <div
                  v-if="['F', 'U'].includes(selectedGisInfoLayerType)"
                  class="flex-grow-1 overflow-y-auto"
                >
                  <GisInfo
                    :globalId="globalId"
                    :selectedMapServiceId="selectedMapServiceId"
                    :objectId="objectId"
                    :gisInfoAttributes="gisInfoAttributes"
                    :featureItemFields="fields"
                    inExpandInfoDialog
                    @esri-field-saved="getFeature"
                    @utilisync-field-saved="getFeature"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="d-flex flex-column">
            <v-card class="my-1 flex-grow-1" id="tasksSection">
              <v-card-title class="font-weight-regular d-flex gap">
                <v-btn
                  fab
                  dark
                  color="#2193F6"
                  x-small
                  :ripple="false"
                  elevation="0"
                  class="disable-pointer"
                >
                  <v-icon>
                    {{ mdiFormatListChecks }}
                  </v-icon>
                </v-btn>
                <div>Tasks</div>
              </v-card-title>
              <v-card-text>
                <TasksTab
                  :globalId="globalId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="objectId"
                  inExpandInfoDialog
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="d-flex flex-column">
            <v-card class="my-1" id="logSection">
              <v-card-title class="font-weight-regular d-flex gap">
                <v-btn
                  fab
                  dark
                  color="#2193F6"
                  x-small
                  :ripple="false"
                  elevation="0"
                  class="disable-pointer"
                >
                  <v-icon>
                    {{ mdiViewList }}
                  </v-icon>
                </v-btn>
                <div>Log</div>
              </v-card-title>
              <v-card-text>
                <Log
                  :globalId="globalId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="objectId"
                  :selectedGisInfoObj="selectedGisInfoObj"
                  inExpandInfoDialog
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-1" id="documentsSection">
              <v-card-title class="font-weight-regular d-flex gap">
                <v-btn
                  fab
                  dark
                  color="#2193F6"
                  x-small
                  :ripple="false"
                  elevation="0"
                  class="disable-pointer"
                >
                  <v-icon>
                    {{ mdiFile }}
                  </v-icon>
                </v-btn>
                <div>Documents</div>
              </v-card-title>
              <v-card-text>
                <DocsTab
                  :globalId="globalId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="+objectId"
                  inExpandInfoDialog
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pt-2 d-flex justify-center align-center" v-else>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiArrowCollapse } from "@mdi/js";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import Log from "@/components/tickets/Log.vue";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import GisInfo from "@/components/mapView/GisInfo";
import {
  mdiGoogleMaps,
  mdiCheckboxMarkedOutline,
  mdiFile,
  mdiFormatListChecks,
  mdiViewList,
} from "@mdi/js";
import { axiosWithNoAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ExpandInfoPanelDialog",
  props: {
    showExpansionPanelDialog: Boolean,
    selectedMapServiceId: String,
    selectedGisInfoObj: Object,
    globalId: String,
    objectId: Number,
  },
  components: {
    TicketFormDefDropdown,
    DocsTab,
    TasksTab,
    Log,
    GisInfo,
  },
  mixins: [gisInfoMixin],
  computed: {
    ...mapGetters(["formSubmitted", "formDeleted"]),
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (!this.attributes || !Array.isArray(this.fields)) {
          return {};
        }

        const entries = this.fields.map(({ name: key }) => {
          const value = this.attributes[key];
          return [this.findAliasByName(this.fields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        const entries = this.gisDataFieldsAndValues.map((fv) => {
          const { name, value } = fv;
          return [name, value];
        });
        return Object.fromEntries(entries);
      }
    },
    notUtiliSyncLayer() {
      return this.selectedMapService.service_type !== "U";
    },
    selectedGisInfoLayerType() {
      return this.selectedMapService.service_type;
    },
    title() {
      return this.selectedMapService.service_name;
    },
    subtitle() {
      if (this.notUtiliSyncLayer) {
        return (
          this.attributes?.[this.selectedMapService?.ref_field] ??
          "[Not Provided]"
        );
      } else {
        const { ref_field: refField } = this.selectedMapService;
        return (
          this.gisDataFieldsAndValues?.find((f) => f.name === refField)
            ?.value ?? "[Not Provided]"
        );
      }
    },
  },
  data() {
    return {
      mdiArrowCollapse,
      mdiGoogleMaps,
      mdiCheckboxMarkedOutline,
      mdiFile,
      mdiFormatListChecks,
      mdiViewList,
      attributes: {},
      fields: [],
      selectedMapService: {},
      gisDataFieldsAndValues: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations(["setFormSubmitted", "setFormDeleted"]),
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async getFeature() {
      const { objectId, selectedMapServiceId } = this;
      const [mapService] = await db.mapServices
        .filter((m) => {
          return m.map_service_id === selectedMapServiceId;
        })
        .toArray();
      this.selectedMapService = mapService;
      if (this.selectedMapService.service_type === "F") {
        const mapServiceUrl = mapService.service_url;
        let queryResult = {};
        try {
          const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
            params: {
              objectids: objectId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          });
          queryResult = data;
        } catch (error) {
          console.log(error);
        } finally {
          const { features, fields } = queryResult;
          const [feature] = features ?? [];
          this.attributes = feature?.attributes ?? {};
          this.fields = fields;
        }
      } else {
        const gisDataValues = await db.gisDataValues.toCollection().toArray();
        const gisDataValueIds =
          gisDataValues?.map((g) => g.gis_data_field_id) ?? [];
        const gisDataFields = await db.gisDataFields
          .filter((g) => {
            return (
              g.map_service_id === selectedMapServiceId &&
              !gisDataValueIds.includes(objectId)
            );
          })
          .toArray();

        this.gisDataFieldsAndValues = gisDataFields?.map((gdf) => {
          const { name } = gdf;
          const value = gisDataValues?.find((gdv) => {
            return (
              gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
              gdv?.feature_id === this.objectId
            );
          })?.value;

          return {
            name,
            value,
          };
        });
      }
    },
  },
  beforeMount() {
    this.getFeature();
  },
  watch: {
    selectedGisInfoObj: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getGisInfoData(val);
        }
      },
    },
    formSubmitted: {
      deep: true,
      immediate: true,
      async handler({ isFormSubmitted }) {
        if (isFormSubmitted) {
          this.isLoading = true;
          await this.getFeature();
          await this.getGisInfoData(this.selectedGisInfoObj);
          this.setFormSubmitted({
            isFormSubmitted: false,
            formResultId: undefined,
          });
          this.isLoading = false;
        }
      },
    },
    formDeleted: {
      deep: true,
      immediate: true,
      async handler({ isFormDeleted }) {
        if (isFormDeleted) {
          this.isLoading = true;
          await this.getFeature();
          await this.getGisInfoData(this.selectedGisInfoObj);
          this.setFormSubmitted({
            isFormDeleted: false,
            formResultId: undefined,
          });
          this.isLoading = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}

.disable-pointer {
  pointer-events: none;
}
</style>
