<template>
  <v-dialog :value="showSiteContactsDialog" persistent max-width="900px">
    <v-card id="siteContactsDialog">
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <h3 class="font-weight-regular">Site Contacts</h3>
        <v-spacer />
        <v-btn icon @click="$emit('site-contacts-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <section class="d-flex justify-end">
          <v-btn
            text
            color="#00A9F4"
            @click="showAddSiteContactDialog = true"
            id="addSiteContactBtn"
          >
            <v-icon>
              {{ mdiPlus }}
            </v-icon>
            Add Site Contact
          </v-btn>
        </section>
        <v-simple-table>
          <thead>
            <tr>
              <th class="pr-3">Name</th>
              <th class="pr-3">Title</th>
              <th class="pr-3">Company</th>
              <th class="pr-3">Email</th>
              <th class="pr-3">Phone Number</th>
              <th class="pr-3">Show Contact on Public Site</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="s of sharedSiteContacts"
              :key="s.site_contact_id"
              @click="
                selectedSiteContactId = s.site_contact_id;
                selectedSiteId = s.site_id;
                showEditSiteContactDialog = true;
              "
            >
              <td>{{ s.f_name }} {{ s.l_name }}</td>
              <td>{{ s.title }}</td>
              <td>{{ s.company }}</td>
              <td>{{ s.email }}</td>
              <td>{{ s.phone_no }}</td>
              <td class="text-center">
                <v-icon v-if="s.show_contact_on_public_page" color="#6BAC6C">
                  {{ mdiCheck }}
                </v-icon>
                <v-icon v-else> {{ mdiMinus }}</v-icon>
              </td>
              <td>
                <div class="d-flex flex-no-wrap justify-end">
                  <v-btn
                    icon
                    @click="
                      selectedSiteContactId = s.site_contact_id;
                      selectedSiteId = s.site_id;
                      showEditSiteContactDialog = true;
                    "
                    color="#00A9F4"
                  >
                    <v-icon>{{ mdiPencil }}</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="#F2545B"
                    @click.stop="
                      shareSiteToDelete = s;
                      showConfirmRemoveSiteContactDialog = true;
                    "
                  >
                    <v-icon>{{ mdiDelete }}</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <AddSiteContactDialog
          v-if="showAddSiteContactDialog"
          :showAddSiteContactDialog="showAddSiteContactDialog"
          :sharedSite="sharedSite"
          :sharedSiteContacts="sharedSiteContacts"
          @add-site-contacts-dialog-close="showAddSiteContactDialog = false"
          @site-added="
            showAddSiteContactDialog = false;
            getSharedSiteContacts();
          "
        />

        <EditSiteContactDialog
          v-if="showEditSiteContactDialog"
          :showEditSiteContactDialog="showEditSiteContactDialog"
          :selectedSiteContactId="selectedSiteContactId"
          :sharedSite="sharedSite"
          :selectedSiteId="selectedSiteId"
          :sharedSiteContacts="sharedSiteContacts"
          @edit-site-contacts-dialog-close="showEditSiteContactDialog = false"
          @site-updated="
            showEditSiteContactDialog = false;
            getSharedSiteContacts();
          "
        />

        <ConfirmRemoveSiteContactDialog
          :showConfirmRemoveSiteContactDialog="
            showConfirmRemoveSiteContactDialog
          "
          @cancel="showConfirmRemoveSiteContactDialog = false"
          @remove-site-contact="removeSiteContact"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5">
        <v-btn
          color="#3F51B5"
          dark
          @click="$emit('site-contacts-dialog-close')"
          id="saveSiteContactsBtn"
        >
          Save Site Contacts
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiCheck,
  mdiMinus,
} from "@mdi/js";
import AddSiteContactDialog from "@/components/app/share-public-site/site-contacts-dialog/AddSiteContactDialog";
import EditSiteContactDialog from "@/components/app/share-public-site/site-contacts-dialog/EditSiteContactDialog";
import ConfirmRemoveSiteContactDialog from "@/components/app/share-public-site/site-contacts-dialog/ConfirmRemoveSiteContactDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SiteContactsDialog",
  props: {
    showSiteContactsDialog: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    sharedSite: Object,
  },
  components: {
    AddSiteContactDialog,
    EditSiteContactDialog,
    ConfirmRemoveSiteContactDialog,
  },
  data() {
    return {
      mdiClose,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiMinus,
      showAddSiteContactDialog: false,
      showEditSiteContactDialog: false,
      sharedSiteContacts: [],
      selectedSiteContactId: undefined,
      showConfirmRemoveSiteContactDialog: false,
      shareSiteToDelete: {},
      selectedSiteId: undefined,
    };
  },
  methods: {
    async removeSiteContact() {
      const { site_contact_id: siteContactId, site_id: siteId } =
        this.shareSiteToDelete;
      await axiosWithRegularAuth.delete(
        `${APIURL}/shared_site_contacts/${siteContactId}/${siteId}`
      );
      this.showConfirmRemoveSiteContactDialog = false;
      await this.getSharedSiteContacts();
    },
    async getSharedSiteContacts() {
      const { selectedMapServiceId, objectId } = this;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/shared_site_contacts`, {
        params: {
          map_service_id: selectedMapServiceId,
          feature_id: objectId,
        },
      });
      this.sharedSiteContacts = results;
    },
  },
  async beforeMount() {
    await this.getSharedSiteContacts();
  },
};
</script>
