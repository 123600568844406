var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        {
          staticClass:
            "\n      d-flex\n      justify-space-between\n      my-2\n      overflow-hidden\n      text-truncate\n      small\n    "
        },
        [
          _c(
            "div",
            { staticClass: "white--text overflow-hidden text-truncate" },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        ]
      ),
      _c("validation-observer", { ref: "sharedSiteEditorForm" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _vm.editing.name
              ? _c("validation-provider", {
                  attrs: {
                    name: "Site Name",
                    rules: {
                      required: true
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c("v-text-field", {
                              staticClass: "field",
                              attrs: {
                                label: "Site Name",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                name: "name",
                                color: "white",
                                height: "20px",
                                dark: ""
                              },
                              model: {
                                value: _vm.currentSharedSite.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.currentSharedSite, "name", $$v)
                                },
                                expression: "currentSharedSite.name"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end py-1" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      outlined: "",
                                      dark: "",
                                      color: "white",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.$set(_vm.editing, "name", false)
                                        _vm.currentSharedSite = Object.assign(
                                          {},
                                          _vm.originalSharedSite
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      outlined: "",
                                      dark: "",
                                      color: "white",
                                      small: ""
                                    },
                                    on: { click: _vm.onSubmit }
                                  },
                                  [_vm._v(" Save ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2346987347
                  )
                })
              : _c(
                  "div",
                  { staticClass: "d-flex align-center gap white--text" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white", "x-small": "" },
                        on: {
                          click: function($event) {
                            _vm.$set(_vm.editing, "name", true)
                            _vm.currentSharedSite = Object.assign(
                              {},
                              _vm.originalSharedSite
                            )
                          }
                        }
                      },
                      [
                        _c("v-icon", [
                          _vm._v(" " + _vm._s(_vm.mdiPencil) + " ")
                        ])
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(_vm._s(_vm.currentSharedSite.name || "Site Name"))
                    ])
                  ],
                  1
                ),
            _vm.editing.address
              ? _c("validation-provider", {
                  attrs: {
                    name: "Address",
                    rules: {
                      required: true
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c("v-text-field", {
                              staticClass: "field",
                              attrs: {
                                label: "Address",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                name: "address",
                                color: "white",
                                height: "20px",
                                dark: ""
                              },
                              model: {
                                value: _vm.currentSharedSite.address,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.currentSharedSite,
                                    "address",
                                    $$v
                                  )
                                },
                                expression: "currentSharedSite.address"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end py-1" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      dark: "",
                                      color: "white",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.$set(_vm.editing, "address", false)
                                        _vm.currentSharedSite = Object.assign(
                                          {},
                                          _vm.originalSharedSite
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      outlined: "",
                                      dark: "",
                                      color: "white",
                                      small: ""
                                    },
                                    on: { click: _vm.onSubmit }
                                  },
                                  [_vm._v(" Save ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1815668170
                  )
                })
              : _c(
                  "div",
                  { staticClass: "d-flex align-center gap white--text small" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white", "x-small": "" },
                        on: {
                          click: function($event) {
                            return _vm.$set(_vm.editing, "address", true)
                          }
                        }
                      },
                      [
                        _c("v-icon", [
                          _vm._v(" " + _vm._s(_vm.mdiPencil) + " ")
                        ])
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(_vm._s(_vm.currentSharedSite.address || "Address"))
                    ])
                  ],
                  1
                )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }