<template>
  <v-dialog :value="showEditSiteContactDialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <h3 class="font-weight-medium">Edit Site Contact</h3>
        <v-spacer />
        <v-btn icon @click="$emit('edit-site-contacts-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <validation-observer
        slim
        ref="siteContactForm"
        v-slot="{ valid: isFormValid }"
      >
        <form @submit.prevent="updateSiteContact">
          <v-card-text class="pt-3">
            <validation-provider
              :rules="{ required: true, email: true }"
              v-slot="{ errors, valid }"
              name="Email"
            >
              <v-text-field
                v-model="siteContact.email"
                class="flex-grow-1"
                label="Email *"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="First Name"
            >
              <v-text-field
                v-model="siteContact.fName"
                class="flex-grow-1"
                label="First Name *"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="Last Name"
            >
              <v-text-field
                v-model="siteContact.lName"
                class="flex-grow-1"
                label="Last Name *"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Title">
              <v-text-field
                v-model="siteContact.title"
                class="flex-grow-1"
                label="Title"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors, valid }" name="Company">
              <v-text-field
                v-model="siteContact.company"
                class="flex-grow-1"
                label="Company"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <validation-provider
              :rules="{ regex: /(\d{3}-?\s?\d{3}-?\s?\d{4}\s?)?(ext. \d{4})?/ }"
              v-slot="{ errors, valid }"
              name="Phone Number"
            >
              <v-text-field
                v-model="siteContact.phoneNumber"
                class="flex-grow-1"
                label="Phone Number"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>

            <v-switch
              v-model="siteContact.showContactOnPublicSite"
              label="Show Contact on Public Site"
            ></v-switch>
          </v-card-text>

          <v-card-actions class="d-flex justify-end pa-5">
            <v-btn
              :disabled="!isFormValid || emailAlreadyLinked"
              type="submit"
              color="#3F51B5"
              :dark="isFormValid && !emailAlreadyLinked"
            >
              Update Site Contact
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiPlus, mdiMagnify } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EditSiteContactDialog",
  props: {
    showEditSiteContactDialog: Boolean,
    selectedSiteContactId: String,
    sharedSite: Object,
    selectedSiteId: String,
    sharedSiteContacts: Array,
  },
  data() {
    return {
      mdiClose,
      mdiPlus,
      mdiMagnify,
      siteContact: {
        email: "",
        fName: "",
        lName: "",
        title: "",
        company: "",
        phoneNumber: "",
        showContactOnPublicSite: false,
      },
      originalSiteContact: {
        email: "",
        fName: "",
        lName: "",
        title: "",
        company: "",
        phoneNumber: "",
        showContactOnPublicSite: false,
      },
      addNewSiteContact: false,
      selectedContact: undefined,
      contacts: [],
      searchContact: undefined,
      siteContactId: undefined,
    };
  },
  computed: {
    emailAlreadyLinked() {
      const { email } = this.siteContact;
      const { feature_id: featureId, map_service_id: mapServiceId } =
        this.sharedSite;
      return (
        this.originalSiteContact.email !== email &&
        Boolean(
          this.sharedSiteContacts.find((s) => {
            return (
              s.email === email &&
              s.feature_id === featureId &&
              s.map_service_id === mapServiceId
            );
          })
        )
      );
    },
  },
  methods: {
    async updateSiteContact() {
      const success = await this.$refs.siteContactForm.validate();
      if (!success) {
        return;
      }
      const {
        email,
        fName,
        lName,
        title,
        company,
        showContactOnPublicSite,
        phoneNumber,
      } = this.siteContact;
      const { siteContactId, selectedSiteId } = this;
      await axiosWithRegularAuth.put(`${APIURL}/contacts/${siteContactId}`, {
        email,
        f_name: fName,
        l_name: lName,
        title,
        company,
        phone_no: phoneNumber || null,
      });
      await axiosWithRegularAuth.put(`${APIURL}/shared_site_contacts`, {
        site_contact_id: siteContactId,
        site_id: selectedSiteId,
        show_contact_on_public_page: showContactOnPublicSite,
      });
      await axiosWithRegularAuth.post(
        `${APIURL}/shared_site_contacts/resend_invite`,
        {
          site_contact_id: siteContactId,
          site_id: selectedSiteId,
        }
      );
      this.$emit("site-updated");
    },
  },
  async beforeMount() {
    if (this.selectedSiteContactId) {
      const {
        data: { results: siteContact },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/shared_site_contacts/${this.selectedSiteContactId}/${this.selectedSiteId}`
      );
      const {
        email,
        title,
        company,
        phone_no: phoneNumber,
        show_contact_on_public_page: showContactOnPublicSite,
        site_contact_id: siteContactId,
        f_name: fName,
        l_name: lName,
      } = siteContact;
      this.siteContact = {
        email,
        title,
        company,
        phoneNumber,
        showContactOnPublicSite,
        fName,
        lName,
      };
      this.originalSiteContact = { ...this.siteContact };
      this.siteContactId = siteContactId;
    }
  },
};
</script>
