<template>
  <v-dialog :value="showContactAlreadyExistsDialog" width="500" persistent>
    <v-card class="m-2">
      <v-card-text>
        <div class="pt-5">
          <h3 class="font-weight-normal">Contact Already Exists</h3>
        </div>
        <div class="pt-5">
          A contact for {{ siteContact.fName }} {{ siteContact.lName }} with the
          email {{ siteContact.email }} already exists. Would you like to add
          {{ siteContact.fName }} {{ siteContact.lName }} to this contact list?
        </div>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-spacer></v-spacer>
        <v-btn text color="#673AB7" @click="$emit('cancel')"> Cancel </v-btn>

        <v-btn
          elevation="0"
          raised
          color="#673AB7"
          dark
          @click="$emit('add-site-contact')"
        >
          Add Site Contact
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ContactAlreadyExistsDialog",
  props: {
    showContactAlreadyExistsDialog: Boolean,
    siteContact: Object,
  },
};
</script>
