<template>
  <v-dialog :value="showContactAlreadyLinkedDialog" width="500" persistent>
    <v-card class="m-2">
      <v-card-text>
        <div class="pt-5">That contact is already linked to this site.</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          raised
          text
          color="#673AB7"
          @click="$emit('close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ContactAlreadyLinkedDialog",
  props: {
    showContactAlreadyLinkedDialog: Boolean,
  },
};
</script>
