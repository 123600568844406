var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showContactAlreadyExistsDialog,
        width: "500",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "m-2" },
        [
          _c("v-card-text", [
            _c("div", { staticClass: "pt-5" }, [
              _c("h3", { staticClass: "font-weight-normal" }, [
                _vm._v("Contact Already Exists")
              ])
            ]),
            _c("div", { staticClass: "pt-5" }, [
              _vm._v(
                " A contact for " +
                  _vm._s(_vm.siteContact.fName) +
                  " " +
                  _vm._s(_vm.siteContact.lName) +
                  " with the email " +
                  _vm._s(_vm.siteContact.email) +
                  " already exists. Would you like to add " +
                  _vm._s(_vm.siteContact.fName) +
                  " " +
                  _vm._s(_vm.siteContact.lName) +
                  " to this contact list? "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#673AB7" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "0",
                    raised: "",
                    color: "#673AB7",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-site-contact")
                    }
                  }
                },
                [_vm._v(" Add Site Contact ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }