import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      sharedSite: {},
      objectId: undefined,
      selectedMapServiceId: undefined,
    };
  },
  methods: {
    async createSharedSiteIfNotExist() {
      if (!navigator.onLine) {
        return;
      }
      const { selectedMapServiceId, objectId, gisInfoId } = this;
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();

      if (!selectedMapService?.site_enabled) {
        return;
      }

      try {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/shared_sites/${selectedMapServiceId}/${objectId}`
        );

        this.sharedSite = results;
      } catch {
        this.sharedSite = {};
      } finally {
        if (Object.keys(this.sharedSite).length === 0) {
          const {
            data: { results },
          } = await axiosWithRegularAuth.post(`${APIURL}/shared_sites`, {
            map_service_id: selectedMapServiceId,
            global_id: gisInfoId,
            feature_id: objectId,
            name: "",
            active: true,
            address: "",
          });
          this.sharedSite = results;
        }
      }
    },
  },
};
