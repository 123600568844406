var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "py-2 px-5 background" },
    [
      [
        _c(
          "div",
          { staticStyle: { padding: "3px" }, attrs: { id: "siteContactsBtn" } },
          [
            _c(
              "v-btn",
              {
                staticClass: "px-0 mx-0 d-flex align-center",
                attrs: { text: "", color: "#00A9F4" },
                on: { click: _vm.checkSharedSiteAndShowSiteContactsDialog }
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "#00A9F4" } },
                  [_vm._v(" " + _vm._s(_vm.mdiMapMarkerAccount) + " ")]
                ),
                _vm._v(" Site Contacts ")
              ],
              1
            )
          ],
          1
        ),
        !_vm.sharedSiteUrlId
          ? _c(
              "v-btn",
              {
                staticClass: "px-1",
                attrs: { text: "", color: "#00A9F4" },
                on: { click: _vm.createPublicLink }
              },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v(" " + _vm._s(_vm.mdiLinkPlus) + " ")
                ]),
                _vm._v(" Create Public Link ")
              ],
              1
            )
          : _c(
              "section",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "px-1 mx-0",
                    attrs: {
                      text: "",
                      to: "/" + _vm.sharedSiteUrlId,
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { color: "#00A9F4" } },
                      [_vm._v(_vm._s(_vm.mdiLink))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mr-1",
                        staticStyle: { color: "#00a9f4" }
                      },
                      [_vm._v("Public Site Link")]
                    ),
                    _c("v-icon", { attrs: { color: "#00A9F4" } }, [
                      _vm._v(_vm._s(_vm.mdiOpenInNew))
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "#00A9F4", icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.copyLinkToClipboard(
                          _vm.FRONT_END_URL + "/" + _vm.sharedSiteUrlId
                        )
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "#00A9F4" } }, [
                      _vm._v(_vm._s(_vm.mdiContentCopy))
                    ])
                  ],
                  1
                ),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { icon: "", color: "#737373" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { color: "#00A9F4" } }, [
                                  _vm._v(_vm._s(_vm.mdiDotsVertical))
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "v-list",
                      { staticClass: "py-0 my-0" },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-0 text",
                                attrs: { text: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.inactivateLink(
                                      _vm.publicSite.public_site_id
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { color: "#00A9F4" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.mdiLinkOff) + " ")]
                                ),
                                _vm._v(" Remove Sharing ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
        _vm.showSiteContactsDialog
          ? _c("SiteContactsDialog", {
              attrs: {
                showSiteContactsDialog: _vm.showSiteContactsDialog,
                selectedMapServiceId: _vm.selectedMapServiceId,
                objectId: _vm.objectId,
                sharedSite: _vm.sharedSite
              },
              on: {
                "site-contacts-dialog-close": function($event) {
                  _vm.showSiteContactsDialog = false
                }
              }
            })
          : _vm._e(),
        _vm.showSiteNameDialog
          ? _c("SiteNameDialog", {
              attrs: {
                showSiteNameDialog: _vm.showSiteNameDialog,
                selectedMapServiceId: _vm.selectedMapServiceId,
                objectId: _vm.objectId
              },
              on: {
                "site-name-saved": _vm.onSiteNameSaved,
                close: function($event) {
                  _vm.showSiteNameDialog = false
                }
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }