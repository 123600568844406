var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { background: _vm.inRightPane } }, [
    _c(
      "section",
      {
        class: _vm.inExpandInfoDialog ? "overflow-y-auto" : undefined,
        style: _vm.contentHeight
      },
      [
        ["U", "F"].includes(_vm.selectedMapServiceType)
          ? [
              _c(
                "section",
                {
                  staticClass:
                    "\n          d-flex\n          align-center\n          justify-space-between\n          px-5\n          header-background\n        "
                },
                [
                  _vm._m(0),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-0 py-3 my-0",
                      attrs: { text: "", height: "0" },
                      on: { click: _vm.reload }
                    },
                    [
                      _c("v-icon", { attrs: { size: "15" } }, [
                        _vm._v(_vm._s(_vm.mdiRefresh))
                      ]),
                      _c("span", { staticClass: "ml-1 caption" }, [
                        _vm._v("Reload")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.firstUtiliSyncFieldGroupFields, function(field) {
                return _c(
                  "div",
                  { key: field.id },
                  [
                    _vm.getUtiliSyncMapServiceFieldType(field) ===
                    _vm.UTILISYNC_FIELD_TYPES.FIELD
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex gap py-1",
                            class: {
                              "cursor-pointer": _vm.isUtiliSyncFieldsEditable(
                                field.fieldId
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.editUtiliSyncField(field)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex field-name caption pl-5 pr-1"
                              },
                              [
                                _vm.getGisDataField(field.fieldId)
                                  ? _c("div", [
                                      _c(
                                        "b",
                                        {
                                          staticClass:
                                            "caption font-weight-bold break-word reduce-height"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getGisDataField(
                                                  field.fieldId
                                                ).name
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            !_vm.editingUtiliSyncField[field.fieldId]
                              ? _c("section", { staticClass: "flex-grow-1" }, [
                                  !_vm.isRichTextField(field.value)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "break-word field-value"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getUtiliSyncFieldValueLabel(
                                                  _vm.getGisDataField(
                                                    field.fieldId
                                                  ),
                                                  field.value
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _c("div", {
                                        staticClass:
                                          "ql-editor break-word field-value",
                                        domProps: {
                                          innerHTML: _vm._s(field.value)
                                        }
                                      })
                                ])
                              : _c(
                                  "section",
                                  [
                                    _vm.getUtiliSyncFieldType(field.fieldId) ===
                                      "string" &&
                                    !_vm.isUtiliSyncFieldRefField(
                                      _vm.getGisDataField(field.fieldId)
                                    )
                                      ? _c("v-switch", {
                                          staticClass: "py-0 my-0",
                                          attrs: {
                                            label: "Rich Text",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value:
                                              _vm.richTextUtiliSyncField[
                                                field.fieldId
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.richTextUtiliSyncField,
                                                field.fieldId,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "richTextUtiliSyncField[field.fieldId]"
                                          }
                                        })
                                      : _vm._e(),
                                    _c("GisFieldInput", {
                                      attrs: {
                                        richTextField:
                                          _vm.richTextUtiliSyncField[
                                            field.fieldId
                                          ],
                                        fieldType: _vm.getUtiliSyncFieldType(
                                          field.fieldId
                                        ),
                                        gisDataFieldOptions: _vm.getGisDataFieldOptionsByGisDataFieldId(
                                          field.fieldId
                                        )
                                      },
                                      on: {
                                        "cancel-edit": function($event) {
                                          return _vm.$set(
                                            _vm.editingUtiliSyncField,
                                            field.fieldId,
                                            false
                                          )
                                        },
                                        input: function($event) {
                                          _vm.onCancelUtiliSyncFieldEdit(field)
                                          _vm.saveUtiliSyncField(
                                            field.fieldId,
                                            field.gisDataValueId,
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: field.value,
                                        callback: function($$v) {
                                          _vm.$set(field, "value", $$v)
                                        },
                                        expression: "field.value"
                                      }
                                    })
                                  ],
                                  1
                                )
                          ]
                        )
                      : _vm.getUtiliSyncMapServiceFieldType(field) ===
                        _vm.UTILISYNC_FIELD_TYPES.ID
                      ? [
                          field.fieldId === "objectId"
                            ? _c("div", { staticClass: "d-flex gap py-1" }, [
                                _vm._m(1, true),
                                _c("div", { staticClass: "field-value" }, [
                                  _vm._v(_vm._s(_vm.objectId))
                                ])
                              ])
                            : field.fieldId === "globalId"
                            ? _c("div", { staticClass: "d-flex gap py-1" }, [
                                _vm._m(2, true),
                                _c("div", { staticClass: "field-value" }, [
                                  _vm._v(_vm._s(_vm.globalId))
                                ])
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              }),
              _c(
                "v-expansion-panels",
                {
                  attrs: { flat: "", multiple: "" },
                  model: {
                    value: _vm.openUtiliSyncFieldPanels,
                    callback: function($$v) {
                      _vm.openUtiliSyncFieldPanels = $$v
                    },
                    expression: "openUtiliSyncFieldPanels"
                  }
                },
                _vm._l(
                  _vm.selectedMapServiceUtiliSyncFieldGroupsToEdit,
                  function(group, index) {
                    return _c(
                      "v-expansion-panel",
                      {
                        key: group.id,
                        staticClass: "nav-border-top",
                        class: { background: _vm.inRightPane },
                        attrs: { value: index }
                      },
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase px-5 py-1 my-0 expansion-panel-header",
                            class: { background: _vm.inRightPane }
                          },
                          [
                            _c("b", { staticClass: "header" }, [
                              _vm._v(_vm._s(group.sectionHeader.sectionLabel))
                            ])
                          ]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          {
                            staticClass: "py-0",
                            class: { background: _vm.inRightPane }
                          },
                          [
                            _c(
                              "section",
                              {
                                staticClass: "ma-0",
                                class: { background: _vm.inRightPane }
                              },
                              _vm._l(group.fields, function(field) {
                                return _c(
                                  "div",
                                  { key: field.id, staticClass: "py-1 ma-0" },
                                  [
                                    _vm.getUtiliSyncMapServiceFieldType(
                                      field
                                    ) === _vm.UTILISYNC_FIELD_TYPES.FIELD
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "d-flex gap",
                                            class: {
                                              background: _vm.inRightPane,
                                              "cursor-pointer": _vm.isUtiliSyncFieldsEditable(
                                                field.fieldId
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editUtiliSyncField(
                                                  field
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex gap" },
                                              [
                                                _c(
                                                  "section",
                                                  {
                                                    staticClass:
                                                      "d-flex field-name pl-5 pr-1"
                                                  },
                                                  [
                                                    _vm.getGisDataField(
                                                      field.fieldId
                                                    )
                                                      ? _c("div", [
                                                          _c(
                                                            "b",
                                                            {
                                                              staticClass:
                                                                "\n                            caption\n                            font-weight-bold\n                            break-word\n                            reduce-height\n                          "
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getGisDataField(
                                                                      field.fieldId
                                                                    ).name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                ),
                                                !_vm.editingUtiliSyncField[
                                                  field.fieldId
                                                ]
                                                  ? _c("section", [
                                                      !_vm.isRichTextField(
                                                        field.value
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "break-word field-value"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getUtiliSyncFieldValueLabel(
                                                                      _vm.getGisDataField(
                                                                        field.fieldId
                                                                      ),
                                                                      field.value
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _c("div", {
                                                            staticClass:
                                                              "ql-editor break-word field-value",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                field.value
                                                              )
                                                            }
                                                          })
                                                    ])
                                                  : _c(
                                                      "section",
                                                      [
                                                        _vm.getUtiliSyncFieldType(
                                                          field.fieldId
                                                        ) === "string" &&
                                                        !_vm.isUtiliSyncFieldRefField(
                                                          _vm.getGisDataField(
                                                            field.fieldId
                                                          )
                                                        )
                                                          ? _c("v-switch", {
                                                              staticClass:
                                                                "py-0 my-0",
                                                              attrs: {
                                                                label:
                                                                  "Rich Text",
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .richTextUtiliSyncField[
                                                                    field
                                                                      .fieldId
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.richTextUtiliSyncField,
                                                                    field.fieldId,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "richTextUtiliSyncField[field.fieldId]"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _c("GisFieldInput", {
                                                          attrs: {
                                                            richTextField:
                                                              _vm
                                                                .richTextUtiliSyncField[
                                                                field.fieldId
                                                              ],
                                                            fieldType: _vm.getUtiliSyncFieldType(
                                                              field.fieldId
                                                            ),
                                                            gisDataFieldOptions: _vm.getGisDataFieldOptionsByGisDataFieldId(
                                                              field.fieldId
                                                            )
                                                          },
                                                          on: {
                                                            "cancel-edit": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.editingUtiliSyncField,
                                                                field.fieldId,
                                                                false
                                                              )
                                                            },
                                                            input: function(
                                                              $event
                                                            ) {
                                                              _vm.onCancelUtiliSyncFieldEdit(
                                                                field
                                                              )
                                                              _vm.saveUtiliSyncField(
                                                                field.fieldId,
                                                                field.gisDataValueId,
                                                                $event
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value: field.value,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                field,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "field.value"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm.getUtiliSyncMapServiceFieldType(
                                          field
                                        ) === _vm.UTILISYNC_FIELD_TYPES.ID
                                      ? [
                                          field.fieldId === "objectId"
                                            ? _c(
                                                "div",
                                                { staticClass: "d-flex gap" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption field-name pl-5 pr-1"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("Feature ID")
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "field-value"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.objectId) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : field.fieldId === "globalId"
                                            ? _c(
                                                "div",
                                                { staticClass: "d-flex gap" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "caption field-name pl-5 pr-1"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("Global ID")
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "field-value"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.globalId) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }
                ),
                1
              )
            ]
          : _vm._e(),
        _vm.notUtiliSyncLayer
          ? _c(
              "section",
              {
                staticClass:
                  "d-flex align-center justify-space-between px-5 header-background"
              },
              [
                _vm._m(3),
                _c(
                  "v-btn",
                  {
                    staticClass: "px-0 py-3 my-0",
                    attrs: { text: "", height: "0" },
                    on: { click: _vm.loadLatestGisData }
                  },
                  [
                    _c("v-icon", { attrs: { size: "15" } }, [
                      _vm._v(_vm._s(_vm.mdiRefresh))
                    ]),
                    _c("span", { staticClass: "ml-1 caption" }, [
                      _vm._v("Reload")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.notUtiliSyncLayer
          ? _vm._l(_vm.firstGisFieldGroupFields, function(field) {
              return _c("div", { key: field.id }, [
                _vm.getGisMapServiceFieldType(field) ===
                _vm.GIS_FIELD_TYPES.FIELD
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex gap",
                        class: {
                          "cursor-pointer": _vm.isGisFieldsEditable(
                            field.fieldId
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.editGisField(field)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "caption field-name pl-5 pr-1" },
                          [
                            _c(
                              "b",
                              {
                                staticClass:
                                  "caption font-weight-bold break-word reduce-height"
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(field.labels.join(", ")) + " "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm.editingGisField[field.fieldId]
                          ? _c(
                              "section",
                              [
                                _vm.getEsriFieldType(field.fieldId) ===
                                  "esriFieldTypeString" &&
                                !Array.isArray(
                                  _vm.getCodeValues(field.fieldId)
                                ) &&
                                !_vm.isEsriFieldRefField(field.fieldId)
                                  ? _c("v-switch", {
                                      staticClass: "py-0 my-0",
                                      attrs: {
                                        label: "Rich Text",
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: _vm.richTextField[field.fieldId],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.richTextField,
                                            field.fieldId,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "richTextField[field.fieldId]"
                                      }
                                    })
                                  : _vm._e(),
                                _c("FeatureItemGisFieldInput", {
                                  attrs: {
                                    richTextField:
                                      _vm.richTextField[field.fieldId],
                                    fieldType: _vm.getEsriFieldType(
                                      field.fieldId
                                    ),
                                    featureItemFields: _vm.featureItemFields,
                                    gisInfoAttributeKey: field.fieldId
                                  },
                                  on: {
                                    "cancel-edit": function($event) {
                                      return _vm.onCancelGisFieldEdit(field)
                                    },
                                    input: function($event) {
                                      return _vm.saveEsriField(
                                        field.fieldId,
                                        $event
                                      )
                                    }
                                  },
                                  model: {
                                    value: field.value,
                                    callback: function($$v) {
                                      _vm.$set(field, "value", $$v)
                                    },
                                    expression: "field.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("section", [
                              !_vm.richTextField[field.fieldId]
                                ? _c(
                                    "div",
                                    { staticClass: "break-word field-value" },
                                    [
                                      _vm.getEsriFieldType(field.fieldId) ===
                                        "esriFieldTypeDate" &&
                                      field.value !== null
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatUtcDate")(
                                                    new Date(field.value)
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _c(
                                            "div",
                                            { staticClass: "field-value" },
                                            [
                                              !Array.isArray(
                                                _vm.getCodeValues(field.fieldId)
                                              )
                                                ? [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(field.value) +
                                                        " "
                                                    )
                                                  ]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getNameForCodeValue(
                                                            field.fieldId,
                                                            field.value
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                    ]
                                  )
                                : _c("div", {
                                    staticClass: "break-word field-value",
                                    domProps: { innerHTML: _vm._s(field.value) }
                                  })
                            ])
                      ]
                    )
                  : _vm._e()
              ])
            })
          : _vm._e(),
        _vm.notUtiliSyncLayer
          ? _c(
              "v-expansion-panels",
              {
                attrs: { flat: "", multiple: "" },
                model: {
                  value: _vm.openGisFieldPanels,
                  callback: function($$v) {
                    _vm.openGisFieldPanels = $$v
                  },
                  expression: "openGisFieldPanels"
                }
              },
              _vm._l(_vm.selectedMapServiceGisFieldGroupsToEdit, function(
                group,
                index
              ) {
                return _c(
                  "v-expansion-panel",
                  {
                    key: group.id,
                    staticClass: "nav-border-top",
                    class: { background: _vm.inRightPane },
                    attrs: { value: index }
                  },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass:
                          "\n            text-uppercase\n            nav-border-top\n            my-0\n            py-1\n            px-5\n            expansion-panel-header\n          ",
                        class: { background: _vm.inRightPane }
                      },
                      [
                        _c("b", { staticClass: "header" }, [
                          _vm._v(_vm._s(group.sectionHeader.sectionLabel))
                        ])
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      {
                        staticClass: "my-0 py-0",
                        class: { background: _vm.inRightPane }
                      },
                      _vm._l(group.fields, function(field) {
                        return _c(
                          "div",
                          { key: field.id, staticClass: "ma-0 py-1" },
                          [
                            _vm.getGisMapServiceFieldType(field) ===
                            _vm.GIS_FIELD_TYPES.FIELD
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex gap",
                                    class: {
                                      "cursor-pointer": _vm.isGisFieldsEditable(
                                        field.fieldId
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editGisField(field)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "caption field-name pl-5 pr-1"
                                      },
                                      [
                                        _c(
                                          "b",
                                          {
                                            staticClass:
                                              "caption font-weight-bold break-word reduce-height"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  field.labels.join(", ")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm.editingGisField[field.fieldId]
                                      ? _c(
                                          "section",
                                          [
                                            _vm.getEsriFieldType(
                                              field.fieldId
                                            ) === "esriFieldTypeString" &&
                                            !Array.isArray(
                                              _vm.getCodeValues(field.fieldId)
                                            ) &&
                                            !_vm.isEsriFieldRefField(
                                              field.fieldId
                                            )
                                              ? _c("v-switch", {
                                                  staticClass: "py-0 my-0",
                                                  attrs: {
                                                    label: "Rich Text",
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.richTextField[
                                                        field.fieldId
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.richTextField,
                                                        field.fieldId,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "richTextField[field.fieldId]"
                                                  }
                                                })
                                              : _vm._e(),
                                            _c("FeatureItemGisFieldInput", {
                                              attrs: {
                                                richTextField:
                                                  _vm.richTextField[
                                                    field.fieldId
                                                  ],
                                                fieldType: _vm.getEsriFieldType(
                                                  field.fieldId
                                                ),
                                                featureItemFields:
                                                  _vm.featureItemFields,
                                                gisInfoAttributeKey:
                                                  field.fieldId
                                              },
                                              on: {
                                                "cancel-edit": function(
                                                  $event
                                                ) {
                                                  return _vm.onCancelGisFieldEdit(
                                                    field
                                                  )
                                                },
                                                input: function($event) {
                                                  return _vm.saveEsriField(
                                                    field.fieldId,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: field.value,
                                                callback: function($$v) {
                                                  _vm.$set(field, "value", $$v)
                                                },
                                                expression: "field.value"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("section", [
                                          !_vm.richTextField[field.fieldId]
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "break-word field-value"
                                                },
                                                [
                                                  _vm.getEsriFieldType(
                                                    field.fieldId
                                                  ) === "esriFieldTypeDate" &&
                                                  field.value !== null
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatUtcDate"
                                                              )(
                                                                new Date(
                                                                  field.value
                                                                )
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "field-value"
                                                        },
                                                        [
                                                          !Array.isArray(
                                                            _vm.getCodeValues(
                                                              field.fieldId
                                                            )
                                                          )
                                                            ? [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      field.value
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getNameForCodeValue(
                                                                        field.fieldId,
                                                                        field.value
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                ]
                                              )
                                            : _c("div", {
                                                staticClass:
                                                  "break-word field-value",
                                                domProps: {
                                                  innerHTML: _vm._s(field.value)
                                                }
                                              })
                                        ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
        _c("ApplyEditResultDialog", {
          attrs: {
            showApplyEditResultDialog: _vm.showApplyEditResultDialog,
            errorMessage: _vm.updateResultErrorMessage
          },
          on: {
            close: function($event) {
              _vm.showApplyEditResultDialog = false
            }
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption" }, [
      _c("b", [_vm._v("UtiliSync Fields")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption field-name pl-5 pr-1" }, [
      _c("b", [_vm._v("Feature ID")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption field-name pl-5 pr-1" }, [
      _c("b", [_vm._v("Global ID")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption" }, [
      _c("b", [_vm._v("GIS Fields")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }