var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showSiteNameDialog, width: "500", persistent: "" } },
    [
      _c("validation-observer", { ref: "siteNameForm", attrs: { slim: "" } }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "v-card",
              { staticClass: "px-4 py-2" },
              [
                _c("v-card-title", [_vm._v(" Name Site ")]),
                _c(
                  "v-card-text",
                  [
                    _c("p", [
                      _vm._v(
                        "Give your site a name before you create a site contacts:"
                      )
                    ]),
                    _c("validation-provider", {
                      attrs: { name: "Value", rules: { required: true } },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            var valid = ref.valid
                            return [
                              _c("v-text-field", {
                                attrs: {
                                  clearable: "",
                                  "error-messages": errors,
                                  success: valid,
                                  label: "Site Name",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.site.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.site, "name", $$v)
                                  },
                                  expression: "site.name"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          elevation: "0",
                          raised: "",
                          text: "",
                          color: "#3F51B5"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "v-btn",
                      { attrs: { color: "#3F51B5", type: "submit", dark: "" } },
                      [_vm._v(" Save ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }