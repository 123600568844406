var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showAddSiteContactDialog,
        persistent: "",
        "max-width": "600px"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { id: "addSiteContactDialog" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("h3", { staticClass: "font-weight-medium" }, [
                _vm._v("Add Site Contact")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-site-contacts-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          !_vm.addNewSiteContact
            ? _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c("v-combobox", {
                    staticStyle: { "max-width": "300px" },
                    attrs: {
                      items: _vm.filteredContacts,
                      "item-value": "site_contact_id",
                      "hide-details": "",
                      label: "Select from Existing Contacts",
                      "append-icon": _vm.mdiMagnify,
                      "search-input": _vm.searchContact,
                      "no-filter": ""
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.searchContact = $event
                      },
                      "update:search-input": function($event) {
                        _vm.searchContact = $event
                      },
                      change: _vm.onContactAutocompleteChange
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("section", [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.f_name) +
                                      " " +
                                      _vm._s(item.l_name)
                                  )
                                ]),
                                _c("div", { staticClass: "caption" }, [
                                  _vm._v(_vm._s(item.email))
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("section", [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.f_name) +
                                      " " +
                                      _vm._s(item.l_name)
                                  )
                                ]),
                                _c("div", { staticClass: "caption" }, [
                                  _vm._v(_vm._s(item.email))
                                ])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2436241020
                    ),
                    model: {
                      value: _vm.selectedContact,
                      callback: function($$v) {
                        _vm.selectedContact = $$v
                      },
                      expression: "selectedContact"
                    }
                  }),
                  _c("hr", { staticClass: "my-3" }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-flex align-center px-0",
                      attrs: {
                        text: "",
                        color: "#00A9F4",
                        id: "newSiteContactBtn"
                      },
                      on: {
                        click: function($event) {
                          _vm.addNewSiteContact = true
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]),
                      _vm._v(" New Site Contact ")
                    ],
                    1
                  )
                ],
                1
              )
            : _c("validation-observer", {
                ref: "siteContactForm",
                attrs: { slim: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var isFormValid = ref.valid
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pt-3" },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    rules: { required: true, email: true },
                                    name: "Email"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              staticClass: "flex-grow-1",
                                              attrs: {
                                                label: "Email *",
                                                "hide-details": "",
                                                "error-messages": errors,
                                                success: valid
                                              },
                                              on: { blur: _vm.onEmailBlur },
                                              model: {
                                                value: _vm.siteContact.email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.siteContact,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "siteContact.email"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    rules: { required: true },
                                    name: "First Name"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              staticClass: "flex-grow-1",
                                              attrs: {
                                                label: "First Name *",
                                                "hide-details": "",
                                                "error-messages": errors,
                                                success: valid
                                              },
                                              model: {
                                                value: _vm.siteContact.fName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.siteContact,
                                                    "fName",
                                                    $$v
                                                  )
                                                },
                                                expression: "siteContact.fName"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    rules: { required: true },
                                    name: "Last Name"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              staticClass: "flex-grow-1",
                                              attrs: {
                                                label: "Last Name *",
                                                "hide-details": "",
                                                "error-messages": errors,
                                                success: valid
                                              },
                                              model: {
                                                value: _vm.siteContact.lName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.siteContact,
                                                    "lName",
                                                    $$v
                                                  )
                                                },
                                                expression: "siteContact.lName"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("validation-provider", {
                                  attrs: { name: "Title" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              staticClass: "flex-grow-1",
                                              attrs: {
                                                label: "Title",
                                                "hide-details": "",
                                                "error-messages": errors,
                                                success: valid
                                              },
                                              model: {
                                                value: _vm.siteContact.title,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.siteContact,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression: "siteContact.title"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("validation-provider", {
                                  attrs: { name: "Company" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              staticClass: "flex-grow-1",
                                              attrs: {
                                                label: "Company",
                                                "hide-details": "",
                                                "error-messages": errors,
                                                success: valid
                                              },
                                              model: {
                                                value: _vm.siteContact.company,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.siteContact,
                                                    "company",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "siteContact.company"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("validation-provider", {
                                  attrs: {
                                    rules: {
                                      regex: /(\d{3}-?\s?\d{3}-?\s?\d{4}\s?)?(ext. \d{4})?/
                                    },
                                    name: "Phone Number"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-text-field", {
                                              staticClass: "flex-grow-1",
                                              attrs: {
                                                label: "Phone Number",
                                                "hide-details": "",
                                                "error-messages": errors,
                                                success: valid
                                              },
                                              model: {
                                                value:
                                                  _vm.siteContact.phoneNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.siteContact,
                                                    "phoneNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "siteContact.phoneNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c("v-switch", {
                                  attrs: {
                                    label: "Show Contact on Public Site"
                                  },
                                  model: {
                                    value:
                                      _vm.siteContact.showContactOnPublicSite,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.siteContact,
                                        "showContactOnPublicSite",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "siteContact.showContactOnPublicSite"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              { staticClass: "d-flex justify-end pa-5" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled:
                                        !isFormValid || _vm.emailAlreadyLinked,
                                      type: "submit",
                                      color: "#3F51B5",
                                      dark:
                                        isFormValid && !_vm.emailAlreadyLinked,
                                      id: "createSiteContactBtn"
                                    }
                                  },
                                  [_vm._v(" Create Site Contact ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
        ],
        1
      ),
      _vm.showContactAlreadyExistsDialog
        ? _c("ContactAlreadyExistsDialog", {
            attrs: {
              showContactAlreadyExistsDialog:
                _vm.showContactAlreadyExistsDialog,
              siteContact: _vm.siteContact
            },
            on: {
              cancel: function($event) {
                _vm.showContactAlreadyExistsDialog = false
              },
              "add-site-contact": function($event) {
                return _vm.autoFillExistingContact()
              }
            }
          })
        : _vm._e(),
      _vm.showContactAlreadyLinkedDialog
        ? _c("ContactAlreadyLinkedDialog", {
            attrs: {
              showContactAlreadyLinkedDialog: _vm.showContactAlreadyLinkedDialog
            },
            on: {
              close: function($event) {
                _vm.showContactAlreadyLinkedDialog = false
                _vm.resetSiteContact()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }