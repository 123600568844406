var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showExpansionPanelDialog,
        fullscreen: "",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center",
                  staticStyle: { "font-size": "12px", width: "100%" }
                },
                [
                  _c("div", { staticClass: "white--text" }, [
                    _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
                    _c("div", { staticClass: "white--text" }, [
                      _vm._v(" " + _vm._s(_vm.subtitle) + " ")
                    ])
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", text: "", id: "collapsePanelBtn" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("expand-info-panel-close")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" " + _vm._s(_vm.mdiArrowCollapse) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          !_vm.isLoading
            ? _c(
                "v-card-text",
                { staticClass: "pt-2" },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "stretch" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "12", md: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1",
                              attrs: { id: "formsSection" }
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("TicketFormDefDropdown", {
                                    attrs: {
                                      globalId: _vm.globalId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.objectId,
                                      selectedGisInfoObj:
                                        _vm.selectedGisInfoObj,
                                      inExpandInfoDialog: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-1",
                              attrs: { id: "siteInfoSection" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "font-weight-regular d-flex gap"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "disable-pointer",
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        color: "#2193F6",
                                        "x-small": "",
                                        ripple: false,
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiGoogleMaps) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("Site Information")])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                ["F", "U"].includes(
                                  _vm.selectedGisInfoLayerType
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-grow-1 overflow-y-auto"
                                      },
                                      [
                                        _c("GisInfo", {
                                          attrs: {
                                            globalId: _vm.globalId,
                                            selectedMapServiceId:
                                              _vm.selectedMapServiceId,
                                            objectId: _vm.objectId,
                                            gisInfoAttributes:
                                              _vm.gisInfoAttributes,
                                            featureItemFields: _vm.fields,
                                            inExpandInfoDialog: ""
                                          },
                                          on: {
                                            "esri-field-saved": _vm.getFeature,
                                            "utilisync-field-saved":
                                              _vm.getFeature
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "12", md: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-1",
                              attrs: { id: "tasksSection" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "font-weight-regular d-flex gap"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "disable-pointer",
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        color: "#2193F6",
                                        "x-small": "",
                                        ripple: false,
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.mdiFormatListChecks) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("Tasks")])
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("TasksTab", {
                                    attrs: {
                                      globalId: _vm.globalId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.objectId,
                                      inExpandInfoDialog: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "12", md: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1",
                              attrs: { id: "logSection" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "font-weight-regular d-flex gap"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "disable-pointer",
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        color: "#2193F6",
                                        "x-small": "",
                                        ripple: false,
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiViewList) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("Log")])
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("Log", {
                                    attrs: {
                                      globalId: _vm.globalId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.objectId,
                                      selectedGisInfoObj:
                                        _vm.selectedGisInfoObj,
                                      inExpandInfoDialog: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-1",
                              attrs: { id: "documentsSection" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "font-weight-regular d-flex gap"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "disable-pointer",
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        color: "#2193F6",
                                        "x-small": "",
                                        ripple: false,
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(" " + _vm._s(_vm.mdiFile) + " ")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("div", [_vm._v("Documents")])
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("DocsTab", {
                                    attrs: {
                                      globalId: _vm.globalId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: +_vm.objectId,
                                      inExpandInfoDialog: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-card-text",
                { staticClass: "pt-2 d-flex justify-center align-center" },
                [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }